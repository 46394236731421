<template>
  <div style="padding: 2rem 0">
    <div>
      <el-input :placeholder="$t('input_keyword') + '...'" v-model="search_input" class="append-primary"
                @keyup.enter.native="m__search_tiktok">
        <el-select v-model="search_type" slot="prepend" placeholder="Select">
          <el-option :label="$t('keyword')" value="keyword"></el-option>
          <el-option label="Hashtag" value="hashtag"></el-option>
        </el-select>
        <el-button type="primary" slot="append" @click="m__search_tiktok" :loading="loading.get_data">
          {{ $t('search') }}
        </el-button>
      </el-input>
    </div>

    <div class="select-section">
      <div>
        <div class="select-section-item">
          <div class="select-title">
            {{ $t('platform') }}
          </div>
          <div class="select-option">
            <div v-for="platform in platform_list" :key="platform.name" style="display: flex; margin-right: 50px">
              <img :src="platform.img" :alt="platform.name" style="width: 16px; height: 16px; object-fit: fill">
              <div style="margin-left: 6px">
                <span :class="{'text-primary': platform.key === selected_platform}"
                      style="font-weight: 500; font-size:16px !important; cursor: pointer">
                  {{ platform.name }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="select-section-item">
          <div class="select-title">
            {{ $t('advance') }}
          </div>
          <div class="select-option">
            <el-select v-model="tiktok_filter.view_count" size="small" value="" :placeholder="$t('views')"
                       style="margin-right: 30px" clearable>
              <el-option value="" :label="$t('views')"></el-option>
              <el-option :value="500" label="> 500"></el-option>
              <el-option :value="1000" label="> 1000"></el-option>
              <el-option :value="5000" label="> 5000"></el-option>
              <el-option :value="10000" label="> 10000"></el-option>
              <el-option :value="1000000" label="> 1M"></el-option>
              <el-option :value="2000000" label="> 2M"></el-option>
              <el-option :value="5000000" label="> 5M"></el-option>
            </el-select>
            <el-select v-model="tiktok_filter.like_count" size="small" value="" :placeholder="$t('likes')"
                       style="margin-right: 30px" clearable>
              <el-option value="" :label="$t('likes')"></el-option>
              <el-option :value="500" label="> 500"></el-option>
              <el-option :value="1000" label="> 1000"></el-option>
              <el-option :value="5000" label="> 5000"></el-option>
              <el-option :value="10000" label="> 10000"></el-option>
              <el-option :value="1000000" label="> 1M"></el-option>
              <el-option :value="2000000" label="> 2M"></el-option>
              <el-option :value="5000000" label="> 5M"></el-option>
            </el-select>
            <el-select v-model="tiktok_filter.comment_count" size="small" value="" :placeholder="$t('comments')"
                       style="margin-right: 30px" clearable>
              <el-option value="" :label="$t('comments')"></el-option>
              <el-option :value="100" label="> 100"></el-option>
              <el-option :value="200" label="> 200"></el-option>
              <el-option :value="500" label="> 500"></el-option>
              <el-option :value="1000" label="> 1000"></el-option>
              <el-option :value="5000" label="> 5000"></el-option>
              <el-option :value="10000" label="> 10000"></el-option>
              <el-option :value="20000" label="> 20000"></el-option>
            </el-select>
            <el-select v-model="tiktok_filter.share_count" size="small" value="" :placeholder="$t('shares')"
                       style="margin-right: 30px" clearable>
              <el-option value="" :label="$t('shares')"></el-option>
              <el-option :value="100" label="> 100"></el-option>
              <el-option :value="200" label="> 200"></el-option>
              <el-option :value="500" label="> 500"></el-option>
              <el-option :value="1000" label="> 1000"></el-option>
              <el-option :value="5000" label="> 5000"></el-option>
              <el-option :value="10000" label="> 10000"></el-option>
              <el-option :value="20000" label="> 20000"></el-option>
            </el-select>
            <!--            <el-select v-model="tiktok_filter.created_time" size="small" value="" :placeholder="$t('released_date')"
                                   style="margin-right: 30px" clearable>
                          <el-option value="" :label="$t('released_date')"></el-option>
                          <el-option value="2d" :label="`< 2 ${$t('day_s')}`"></el-option>
                          <el-option value="5d" :label="`< 5 ${$t('day_s')}`"></el-option>
                          <el-option value="1w" :label="`< 1 ${$t('week_s')}`"></el-option>
                          <el-option value="2w" :label="`< 2 ${$t('week_s')}`"></el-option>
                          <el-option value="3w" :label="`< 3 ${$t('week_s')}`"></el-option>
                          <el-option value="1m" :label="`< 1 ${$t('month_s')}`"></el-option>
                          <el-option value="2m" :label="`< 2 ${$t('month_s')}`"></el-option>
                        </el-select>-->
          </div>
        </div>
        <div class="select-section-item select-section-last-item">
          <div class="select-title">
            {{ $t('display') }}
          </div>
          <div class="select-option">
            <!--            <el-select v-model="tiktok_filter.country" size="small" value="vietnam" :placeholder="$t('country')"
                                   style="margin-right: 30px" clearable>
                          <el-option value="vietnam" label="Vietnam"></el-option>
                        </el-select>-->
            <el-select v-model="tiktok_filter.sort_by" size="small" value="" :placeholder="$t('sort_by')"
                       style="margin-right: 30px" clearable>
              <el-option value="" :label="$t('sort_by')"></el-option>
              <el-option value="latest" :label="$t('sort_by') + ' ' + $t('latest_video')"></el-option>
              <el-option value="views" :label="$t('sort_by') + ' ' + $t('views')"></el-option>
              <el-option value="likes" :label="$t('sort_by') + ' ' + $t('likes')"></el-option>
              <el-option value="comments" :label="$t('sort_by') + ' ' + $t('comments')"></el-option>
              <el-option value="shares" :label="$t('sort_by') + ' ' + $t('shares')"></el-option>
            </el-select>
            <el-select v-model="tiktok_filter.page_size" size="small" :value="12" :placeholder="$t('select')"
                       style="margin-right: 30px" clearable>
              <el-option :value="12" :label="$t('results_per_page', {number: 12})"></el-option>
              <el-option :value="16" :label="$t('results_per_page', {number: 16})"></el-option>
              <el-option :value="20" :label="$t('results_per_page', {number: 20})"></el-option>
            </el-select>
            <el-select v-model="tiktok_filter.max_results" size="small" :value="50" :placeholder="$t('select')"
                       style="margin-right: 30px" clearable>
              <el-option v-for="i in max_results_option" :key="i.value" :value="i.value"
                         :label="$t('up_to_results', {number: i.value})"></el-option>
            </el-select>
            <el-select v-model="tiktok_filter.is_ads" size="small" :value="50" :placeholder="$t('select')"
                       style="margin-right: 30px" clearable>
              <el-option :value="false" :label="$t('all')"></el-option>
              <el-option :value="true" :label="$t('ads_video')"></el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>

    <div class="download-section">
      <el-button-group>
        <el-button :type="display_type === 'box' ? 'primary': 'default'" icon="el-icon-menu" size="small"
                   @click="display_type = 'box'"></el-button>
        <el-button :type="display_type === 'table' ? 'primary': 'default'" icon="el-icon-s-fold" size="small"
                   @click="display_type = 'table'"></el-button>
      </el-button-group>

      <el-button size="small" style="margin-left: 20px" icon="el-icon-download" :loading="loading.download_excel"
                 @click="m__download_excel">
        {{ $t('download') }}
      </el-button>
    </div>

    <div class="result-section">
      <div class="result-section-list">
        <div v-for="item in c__tiktok_display_list" :key="item.id" class="result-section-item">
          <div class="save" @click="m__save_data(item, 'tiktok')">
            <i class="el-icon-star-off" aria-hidden="true"></i>
          </div>
          <div v-if="item.isAd" class="is-ads">
            AD
          </div>
          <div class="header-section">
            <div class="avatar">
              <img :src="item.avatarPath ? $api.URL_API_SRV_8_STATIC + item.avatarPath : item.author.avatarThumb"
                   alt="test">
            </div>
            <div class="title" @click="open_link(`https://tiktok.com/@${item.author.uniqueId}`)">
              {{ item.author.nickname }}
            </div>
          </div>
          <div class="video-section">
            <img :src="item.imagePath ? $api.URL_API_SRV_8_STATIC + item.imagePath : item.video.cover"
                 alt="test" class="filter">
            <video controls="controls" controlslist="nodownload" oncontextmenu="return false"
                   :src="item.videoPath ? $api.URL_API_SRV_8_STATIC + item.videoPath : item.video.playAddr"></video>
          </div>
          <div class="name-section">
            <div class="name">
              {{ item.desc }}
            </div>
            <div class="detail">
              <div class="link">
                <a :href="`https://m.tiktok.com/v/${item.id || item.tiktokId}.html`" target="_blank">
                  https://m.tiktok.com/v/{{ item.id || item.tiktokId }}.html
                </a>
              </div>
              <div>
                <el-button size="small" @click="open_link(`/detail/tiktok/${item.id || item.tiktokId}`)">
                  {{ $t('detail') }} >>
                </el-button>
              </div>
            </div>
          </div>
          <div style="padding: 0 4px;">
            <p style="border-top: 2px solid rgb(233, 233, 233);"></p>
          </div>
          <div class="info-section">
            <div class="info-section-list">
              <div class="info-section-item">
                <div class="value">
                  {{ short_value(item.stats.playCount) }}
                </div>
                <div class="title">
                  Views
                </div>
              </div>
              <div class="info-section-item">
                <div class="value">
                  {{ short_value(item.stats.diggCount) }}
                </div>
                <div class="title">
                  Likes
                </div>
              </div>
              <div class="info-section-item">
                <div class="value">
                  {{ short_value(item.stats.commentCount) }}
                </div>
                <div class="title">
                  Comments
                </div>
              </div>
            </div>
            <div class="info-section-list">
              <div class="info-section-item">
                <div class="value">
                  {{ short_value(item.stats.shareCount) }}
                </div>
                <div class="title">
                  Shares
                </div>
              </div>
              <div class="info-section-item">
                <div class="value">
                  {{ item.challenges && item.challenges.length ? item.challenges.length : 0 }}
                </div>
                <div class="title">
                  Hashtags
                </div>
              </div>
              <div class="info-section-item">
                <div class="value">
                  {{ item.createTime * 1000 | moment('DD/MM/YYYY') }}
                </div>
                <div class="title">
                  Release time
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 30px; text-align: center">
      <el-pagination :current-page.sync="tiktok_filter.current_page" :page-size="tiktok_filter.page_size"
                     layout="prev, pager, next, total" :total="c__tiktok_filter_list.length" background/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { drop as _drop, take as _take, orderBy as _orderBy } from 'lodash'

export default {
  name: 'home_component',

  data () {
    return {
      search_input: '',
      search_type: 'keyword',
      display_type: 'box',
      loading: {
        get_data: false,
        download_excel: false
      },
      show_dialog: {},
      selected_platform: 'tiktok',
      platform_list: [
        {
          key: 'tiktok',
          name: 'Tiktok',
          img: 'https://lf16-tiktok-web.ttwstatic.com/obj/tiktok-web-common-sg/mtact/static/images/logo_144c91a.png'
        }
        /* {
          key: 'shopee',
          name: 'Shopee',
          img: 'https://shopee.vn/favicon.ico'
        } */
      ],

      tiktok_filter: {
        page_size: 12,
        current_page: 0,
        max_results: 50,
        sort_by: '',
        country: 'vietnam',
        keyword: '',
        view_count: '',
        play_count: '',
        like_count: '',
        comment_count: '',
        share_count: '',
        created_time: '',
        is_ads: false
      },

      tiktok_list: [],
      shopee_list: [],

      max_results_option: [
        {
          value: 50
        },
        {
          value: 100
        },
        {
          value: 150
        }
      ]
    }
  },

  async beforeMount () {
    await this.m__init_max_results()

    if (this.$route.query.hashtag?.trim()) {
      this.search_input = this.$route.query.hashtag.trim()
      this.search_type = 'hashtag'
      await this.m__search_tiktok()
    } else if (this.$route.query.keyword?.trim()) {
      this.search_input = this.$route.query.keyword.trim()
      this.search_type = 'keyword'
      await this.m__search_tiktok()
    } else {
      await this.m__get_most_view_tiktok_video()
    }
  },

  computed: {
    c__tiktok_filter_list () {
      let result = this.tiktok_list

      if (this.tiktok_filter.view_count) {
        result = result.filter(i => i.stats.playCount >= this.tiktok_filter.view_count)
      }

      if (this.tiktok_filter.like_count) {
        result = result.filter(i => i.stats.diggCount >= this.tiktok_filter.like_count)
      }

      if (this.tiktok_filter.comment_count) {
        result = result.filter(i => i.stats.commentCount >= this.tiktok_filter.comment_count)
      }

      if (this.tiktok_filter.share_count) {
        result = result.filter(i => i.stats.shareCount >= this.tiktok_filter.share_count)
      }

      if (this.tiktok_filter.is_ads) {
        result = result.filter(i => i.isAd)
      }

      // <el-option value="2d" :label="`< 2 ${$t('day_s')}`"></el-option>
      // <el-option value="5d" :label="`< 5 ${$t('day_s')}`"></el-option>
      // <el-option value="1w" :label="`< 1 ${$t('week_s')}`"></el-option>
      // <el-option value="2w" :label="`< 2 ${$t('week_s')}`"></el-option>
      // <el-option value="3w" :label="`< 3 ${$t('week_s')}`"></el-option>
      // <el-option value="1m" :label="`< 1 ${$t('month_s')}`"></el-option>
      // <el-option value="2m" :label="`< 2 ${$t('month_s')}`"></el-option>
      // if (this.tiktok_filter.created_time) {
      //   if (this.tiktok_filter.created_time === '2d') {
      //     result = result.filter(i => moment(i.createTime * 1000))
      //   }
      // }

      if (this.tiktok_filter.sort_by) {
        if (this.tiktok_filter.sort_by === 'latest') {
          result = _orderBy(result, ['createTime'], ['desc'])
        }
        if (this.tiktok_filter.sort_by === 'views') {
          result = _orderBy(result, ['stats.playCount'], ['desc'])
        }
        if (this.tiktok_filter.sort_by === 'likes') {
          result = _orderBy(result, ['stats.diggCount'], ['desc'])
        }
        if (this.tiktok_filter.sort_by === 'comments') {
          result = _orderBy(result, ['stats.commentCount'], ['desc'])
        }
        if (this.tiktok_filter.sort_by === 'shares') {
          result = _orderBy(result, ['stats.shareCount'], ['desc'])
        }
      }

      return result
    },

    c__tiktok_display_list () {
      const limit = this.tiktok_filter.page_size
      const skip = limit * (this.tiktok_filter.current_page - 1)

      return _take(_drop(this.c__tiktok_filter_list, skip), limit)
    }
  },

  methods: {
    async m__init_max_results () {
      if (this.$store.state.token) {
        const current_package_response = await this.$http.get(this.$api.store_get_current_package, this.cf())
        if (current_package_response && current_package_response.data) {
          const current_package = current_package_response.data.data.extend_package.find(i => i.channel === 'atosa_spy')
          if (current_package) {
            const { max_results } = current_package.package.extra_data
            this.max_results_option = this.max_results_option.filter(i => i.value <= max_results)
            this.tiktok_filter.max_results = max_results
          }
        }
      }
    },

    async m__save_data (data, type) {
      try {
        await this.$confirm(this.$t('save_confirm'), 'Info', {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('cancel'),
          type: 'info'
        })
      } catch (e) {
        return
      }

      try {
        await this.$http.post(this.$api.URL_API_SRV_8 + '/save_data', {
          item_id: data.id || data.tiktokId,
          type: type
        }, this.cf())
        this.ntf_s(this.$t('update_successfully'))
      } catch (e) {
        console.error(e)
        this.ntf_w(e)
      }
    },

    async m__get_most_view_tiktok_video () {
      const tiktok_response = await this.$http.get(this.$api.URL_API_SRV_8 + '/tiktok/most_views', this.cf())
      this.tiktok_list = tiktok_response.data.data
    },

    async m__download_excel () {
      if (this.tiktok_list.length) {
        this.loading.download_excel = true
        try {
          const data = [
            [this.$t('title'), this.$t('views'), this.$t('likes'), this.$t('comments'), this.$t('shares'), this.$t('released_date'), 'Profile', 'Video']
          ]

          for (const item of this.tiktok_list) {
            const row = [item.desc, item.stats.playCount, item.stats.diggCount, item.stats.commentCount, item.stats.shareCount, moment(item.createTime).format('DD-MM-YYYY'), `https://tiktok.com/@${item.author.uniqueId}`, `https://m.tiktok.com/v/${item.id || item.tiktokId}.html`]
            data.push(row)
          }

          const filename = `tiktok-${this.remove_unicode(this.search_input)}-${moment().format('DDMMYYYY-HHmm')}.xlsx`
          const response = await this.$http.post(this.$api.URL_API_SRV_2 + '/mipee/export_excel',
            {
              data: data,
              filename: filename
            },
            {
              ...this.cf(),
              responseType: 'blob'
            }
          )

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        } catch (e) {
          console.error(e)
          this.ntf_w(this.$t('an_error'))
        } finally {
          this.loading.download_excel = false
        }
      }
    },

    async m__search_tiktok () {
      if (!this.$store.state.token) {
        this.EB.$emit('require_login')
        return
      }

      if (!this.search_input.trim()) {
        return
      }

      if (this.search_type === 'keyword') {
        await this.m__search_tiktok_by_keyword()
      }

      if (this.search_type === 'hashtag') {
        await this.m__search_tiktok_by_hashtag()
      }
    },

    async m__search_tiktok_by_keyword () {
      this.tiktok_list = []
      this.loading.get_data = true
      try {
        let offset = 0
        let tiktok_response = await this.$http.post(this.$api.URL_API_SRV_8 + '/tiktok/search/keyword', {
          keyword: this.search_input.trim(),
          offset: offset
        }, this.cf())
        while (tiktok_response && tiktok_response.data && tiktok_response.data.data && tiktok_response.data.data.items) {
          // this.tiktok_list = tiktok_response.data.data.items
          for (const item of tiktok_response.data.data.items) {
            if (this.tiktok_list.length >= this.tiktok_filter.max_results) break
            this.tiktok_list.push(item)
          }

          if (this.tiktok_list.length >= this.tiktok_filter.max_results) break

          offset += 12
          tiktok_response = await this.$http.post(this.$api.URL_API_SRV_8 + '/tiktok/search/keyword', {
            keyword: this.search_input.trim(),
            offset: offset
          }, this.cf())
        }

        this.ntf_s(this.$t('load_success'))
      } catch (e) {
        console.error(e)
        this.m__handle_error(e)
      } finally {
        this.loading.get_data = false
      }
    },

    async m__search_tiktok_by_hashtag () {
      this.tiktok_list = []
      this.loading.get_data = true
      try {
        let offset = 0
        let tiktok_response = await this.$http.post(this.$api.URL_API_SRV_8 + '/tiktok/search/hashtag', {
          hashtag: this.search_input.trim(),
          offset: offset
        }, this.cf())
        while (tiktok_response && tiktok_response.data && tiktok_response.data.data && tiktok_response.data.data.items) {
          // this.tiktok_list = tiktok_response.data.data.items
          for (const item of tiktok_response.data.data.items) {
            if (this.tiktok_list.length >= this.tiktok_filter.max_results) break
            this.tiktok_list.push(item)
          }

          if (this.tiktok_list.length >= this.tiktok_filter.max_results) break

          offset += 30
          tiktok_response = await this.$http.post(this.$api.URL_API_SRV_8 + '/tiktok/search/hashtag', {
            hashtag: this.search_input.trim(),
            offset: offset
          }, this.cf())
        }

        this.ntf_s(this.$t('load_success'))
      } catch (e) {
        console.error(e)
        this.m__handle_error(e)
      } finally {
        this.loading.get_data = false
      }
    },

    m__handle_error (error) {
      if (error.data && error.data.code && error.data.code === 81) {
        // this.EB.$emit('require_license')
        this.$router.push('/pricing?msg=upgrade_license')
      } else {
        this.ntf_w(error)
      }
    }
  }
}
</script>
