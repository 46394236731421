<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
      <div class="container">
        <router-link class="navbar-brand" to="/">
          Ato<span style="color: #6927ff">Spy</span>
        </router-link>
        <el-dropdown trigger="click" @command="m__load_language">
          <div style="display: flex; align-items: center; cursor: pointer">
            <img :src="selected_i18n.flag" alt="" style="width: 30px; height: 30px; margin:0 5px">
            <i class="el-icon-arrow-down" style="font-size: 12px; font-weight: bold"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="language of language_list" :key="language.key" :command="language.lang">
              <div style="display: flex; margin-bottom: 5px; margin-right: 20px">
                <img :src="language.flag" alt="" style="width: 30px; height: 30px; margin:0 5px">
                <span :class="{'text-primary': language.lang === i18n.locale}">{{ language.name }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="oi oi-menu"></span> Menu
        </button>

        <div class="collapse navbar-collapse" id="ftco-nav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link to="/" class="nav-link" exact-active-class="active">SPY DATA</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/pricing" class="nav-link" exact-active-class="active" target="_blank">
                {{ $t('pricing') }}
              </router-link>
            </li>
            <li class="nav-item">
              <a href="https://atosa.asia" target="_blank" class="nav-link">{{ $t('about_us') }}</a>
            </li>
            <template v-if="$store.state.token">
              <li class="nav-item">
                <router-link to="/saved" class="nav-link" target="_blank">{{ $t('saved_file_list') }}</router-link>
              </li>
              <li class="nav-item" @click="$refs.profile_dropdown.handleClick()">
                <a href="javascript:void(0)" class="nav-link">
                  <el-dropdown ref="profile_dropdown" trigger="click" @command="m__handle_profile">
                    <span style="font-size: 13px">
                      {{ $store.state.userInfo.display_name }}
                      <div style="display: inline-block; margin: 0 8px 0 5px">
                        <div
                          style="border: 1px solid #1C9EFF; border-radius: 4px; padding: 0 5px; color: #1C9EFF; font-size: 13px">
                          {{
                            current_package && current_package.package && current_package.package.description
                              ? current_package.package.description
                              : 'FREE'
                          }}
                        </div>
                      </div>
                      <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="profile">{{ $t('profile') }}</el-dropdown-item>
                      <el-dropdown-item>{{ $t('query_history') }}</el-dropdown-item>
                      <!--                      <el-dropdown-item>{{ $t('package') }}</el-dropdown-item>-->
                      <el-dropdown-item divided command="logout">{{ $t('logout') }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </a>
              </li>
            </template>
            <template v-else>
              <li class="nav-item" @click="tab = 'register'; show_dialog.authenticate = true">
                <a href="javascript:void(0)" class="nav-link">{{ $t('register') }}</a>
              </li>
              <li class="nav-item cta" @click="tab = 'login'; show_dialog.authenticate = true">
                <a href="javascript:void(0)" class="nav-link"><span>{{ $t('login') }}</span></a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </nav>

    <section class="ftco-section services-section" style="padding: 1rem 0">
      <div class="container">
        <router-view/>
      </div>
    </section>

    <el-dialog :visible.sync="show_dialog.authenticate" :close-on-click-modal="false" width="500px" class="auth-dialog">
      <div style="padding-top: 0">
        <el-tabs class="fixed-tab" v-model="tab" :lazy="true" :stretch="true" style="margin-bottom: 20px">
          <el-tab-pane :label="$t('register')" name="register"></el-tab-pane>
          <el-tab-pane :label="$t('login')" name="login"></el-tab-pane>
        </el-tabs>

        <div v-if="tab === 'register'">
          <div style="font-weight: 600; font-size: 16px; margin-bottom: 30px; text-align: center" class="text-primary">
            {{ $t('should_login') }}
          </div>

          <form @submit="m__register">
            <p style="font-weight: 500; margin: 20px 0 0; font-size: 15px;">
              {{ $t('display_name') }}
            </p>
            <el-input v-model="register_form.name"
                      :placeholder="`${$t('enter')} ${$t('display_name')}`"></el-input>

            <p style="font-weight: 500; margin: 20px 0 0; font-size: 15px;">
              {{ $t('email') }}
            </p>
            <el-input v-model="register_form.email"
                      :placeholder="`${$t('enter')} ${$t('email')}`"></el-input>

            <p style="font-weight: 500; margin: 20px 0 0; font-size: 15px;">
              {{ $t('phone_number') }}
            </p>
            <el-input v-model="register_form.phone"
                      :placeholder="`${$t('enter')} ${$t('phone_number')}`"></el-input>

            <div class="text-primary" style="margin:15px 0 0;">
              <span style="font-weight: 500; font-size: 15px; cursor:pointer;">
                {{ $t('register_note') }}
              </span>
            </div>

            <div class="text-primary" style="font-weight: 500; margin: 40px 0 0; font-size: 15px; text-align: right">
              <el-button style="width: 100%" type="primary" native-type="submit" @click="m__register"
                         :loading="register_form.loading">
                {{ $t('register') }}
              </el-button>
            </div>
          </form>
        </div>

        <div v-if="tab === 'login'">
          <div style="font-weight: 600; font-size: 16px; margin-bottom: 30px; text-align: center" class="text-primary">
            {{ $t('should_login') }}
          </div>

          <form @submit="m__login">
            <p style="font-weight: 500; margin: 0; font-size: 15px;">
              {{ $t('username') }}
            </p>
            <el-input v-model="login_form.username"
                      :placeholder="`${$t('enter')} ${$t('username')} ${$t('or')} ${$t('email')}`"></el-input>

            <p style="font-weight: 500; margin:20px 0 0; font-size: 15px;">
              {{ $t('password') }}
            </p>
            <el-input v-model="login_form.password" type="password" :placeholder="`${$t('enter')} ${$t('password')}`"
                      show-password></el-input>

            <div class="text-primary" style="margin:15px 0 0;">
            <span style="font-weight: 500; font-size: 15px; cursor:pointer;"
                  @click="open_link('https://global.atosa.asia/forgot-password')">
              {{ $t('forgot_password') }}?
            </span>
            </div>

            <div class="text-primary" style="font-weight: 500; margin: 40px 0 0; font-size: 15px; text-align: right">
              <el-button style="width: 100%" type="primary" native-type="submit" @click="m__login"
                         :loading="login_form.loading">
                {{ $t('login') }}
              </el-button>
            </div>
          </form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import i18n from '@/shared/lang'

export default {
  name: 'atospy-web',

  data () {
    return {
      i18n: i18n,
      selected_i18n: {},
      show_dialog: {
        authenticate: false,
        purchasing_license: false
      },
      tab: 'login',
      register_form: {
        ip: '0.0.0.0',
        name: '',
        phone: '',
        email: '',
        response: '',
        mipee: false,
        sendo_like: false,
        atosa: false,
        captcha_version: 'v3',
        loading: false
      },
      login_form: {
        username: '',
        password: '',
        version_no: 'atosa-client',
        loading: false
      },
      current_package: null
    }
  },

  async beforeMount () {
    await this.m__get_profile()

    this.m__load_language(localStorage.getItem('lang') || window.navigator.language)

    this.EB.$on('require_login', () => {
      this.tab = 'login'
      this.show_dialog.authenticate = true
    })

    this.EB.$on('require_license', () => {
      this.show_dialog.purchasing_license = true
    })
  },

  methods: {
    async m__register (e) {
      e.preventDefault()

      await this.m__reload_recaptcha()

      // if (this.register_form.name.trim().length < 4) {
      //   this.ntf_w(this.$t('input_display_name'))
      //   return 0
      // }
      if (this.register_form.phone.trim().length < 4) {
        this.ntf_w(this.$t('input_phone_number'))
        return 0
      }
      if (this.register_form.email.length < 6 || !this.register_form.email.includes('@')) {
        this.ntf_w(this.$t('input_email'))
        return 0
      }

      try {
        await this.$confirm(this.$t('register_confirm', { email: this.register_form.email }), this.$t('info'), {
          type: 'info',
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        })
      } catch (e) {
        return
      }

      try {
        this.register_form.loading = true
        await this.$http.post(this.$api.register_final, {
          ...this.register_form,
          ref: this.ref,
          rkey: this.rkey
        }, this.cf())

        this.register_form.loading = false
        await this.m__reload_recaptcha()
        // this.success_dialog = true
        this.tab = 'login'
        this.login_form.username = this.register_form.email
        this.login_form.password = ''
        this.ntf_s(this.$t('register_successfully'))
      } catch (e) {
        this.ntf_w(e)
      } finally {
        this.register_form.loading = false
        await this.m__reload_recaptcha()
      }
    },

    async m__reload_recaptcha () {
      this.register_form.response = await this.m__get_recaptcha_token()
    },

    m__get_recaptcha_token () {
      return new Promise(resolve => {
        grecaptcha.ready(async () => {
          const token = await grecaptcha.execute('6Lf3isIaAAAAAEEx5IBmKbf0Zdef-jGA8unA2XdB', { action: 'validate_captcha' })
          resolve(token)
        })
      })
    },

    m__load_language (command) {
      if (command === this.selected_i18n.lang) return

      let language = this.language_list.find(i => i.lang === command)
      if (!language) {
        language = this.language_list.find(i => i.lang === 'en')
      }

      this.selected_i18n = { ...language }
      this.i18n.locale = language.lang
      localStorage.setItem('lang', language.lang)
    },

    async m__get_profile () {
      try {
        if (this.$store.state.token) {
          const response = await this.$http.get(this.$api.profile, this.cf())
          await this.$store.dispatch('set__user_info', response.data.data)
          await this.$store.dispatch('set__token', response.data.data.token)

          const current_package_response = await this.$http.get(this.$api.store_get_current_package, this.cf())
          if (current_package_response && current_package_response.data) {
            this.current_package = current_package_response.data.data.extend_package.find(i => i.channel === 'atosa_spy')
          }
        }
      } catch (e) {
      }
    },

    async m__handle_profile (command) {
      if (command === 'logout') {
        await this.m__logout()
      }
      if (command === 'profile') {
        await this.$router.push('/profile')
      }
    },

    async m__login (e) {
      e.preventDefault()

      this.login_form.loading = true
      try {
        const {
          username,
          password,
          version_no
        } = this.login_form

        const response = await this.$http.post(this.$api.store_account__login, {
          username,
          password,
          version_no
        }, {})

        await this.$store.dispatch('set__user_info', response.data.data)
        await this.$store.dispatch('set__token', response.data.data.token)

        await this.m__get_profile()

        // const resp = await this.$http.get(this.API.store_get_current_package, this.cf())
        // await this.$store.dispatch('set__package', resp.body.data)
        // localStorage.setItem('pack', JSON.stringify(resp.body.data))

        this.ntf_s(this.$t('login_successfully'))
        this.show_dialog.authenticate = false
      } catch (e) {
        console.log(e)
        this.ntf_w(e)
      } finally {
        this.login_form.loading = false
      }
    },

    async m__logout () {
      await this.$store.dispatch('set__user_info', {})
      await this.$store.dispatch('set__token', '')
      window.location.reload(true)
    }
  }
}
</script>

<style>
#app {
  background-color: #f3f4f6;
  min-height: 100vh;
}

.fixed-tab .el-tabs__item {
  font-size: 16px;
}

.auth-dialog .el-dialog__header {
  padding: 5px 0;
}

.auth-dialog .el-dialog {
  margin-top: 10vh !important;
}
</style>

<style>
.append-primary {
  max-width: 1000px;
}

.append-primary .el-input-group__append {
  border: 1px solid;
  border-color: var(--primary);
  background-color: var(--primary);
  border-left: unset;
  border-right: unset;
}

.append-primary .el-input-group__append button {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.el-input-group__append:hover {
  background: #66b1ff;
  border-color: #66b1ff;
}

.select-section {
  background-color: #fff;
  margin-top: 20px;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 5%);
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  padding: 0 5px
}

.select-section-item {
  display: flex;
  border-bottom: 1px solid;
  border-color: rgba(243, 244, 246, 1);
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
}

.select-section-last-item {
  border: 0 !important;
}

.select-section-item .select-title {
  width: 120px;
  padding: 15px 10px;
  display: flex;
  align-items: center;
}

.select-section-item .select-option {
  width: calc(100% - 120px);
  padding: 15px 30px;
  display: flex;
  align-items: center;
  overflow: auto;
}

.download-section {
  margin-top: 30px;
  text-align: right;
}

.result-section {
  margin-top: 10px;
}

.result-section-list {
  display: inline-grid;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}

.result-section-item {
  width: 100%;
  min-width: 0;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  position: relative;
  padding: 10px 15px;
}

.result-section-item .header-section {
  display: flex;
  padding: 5px 5px 5px 0;
}

.result-section-item .header-section .avatar {
  width: 40px;
}

.result-section-item .header-section .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  object-fit: fill;
}

.result-section-item .header-section .title {
  width: calc(100% - 50px);
  font-size: 15px;
  font-weight: 600;
  padding: 0 10px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.result-section-item .header-section .title:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.result-section-item .save {
  position: absolute;
  top: 12px;
  right: 12px;
  line-height: 0;
}

.result-section-item .is-ads {
  position: absolute;
  right: 0;
  top: 45px;
  background-color: #FF8D73;
  font-size: 12px;
  color: #fff;
  text-align: center;
  padding: 0 10px;
  font-weight: 600;
}

.result-section-item .save i {
  cursor: pointer;
  font-size: 20px;
}

.result-section-item .save i:hover {
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}

.result-section-item .video-section {
  width: 100%;
  height: 220px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.result-section-item .video-section .filter {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(10px);
  z-index: 888;
  object-fit: cover;
}

.result-section-item .video-section video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 889;
}

.info-section {
}

.info-section-list {
  display: flex;
  flex: 1 1 0;
}

.info-section-list {
  display: flex;
  margin-bottom: 10px;
}

.info-section-item {
  flex: 1 1 auto;
}

.info-section-item .value {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.info-section-item .title {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.name-section {
  margin: 20px 0 20px;
  /*display: flex;*/
}

.name-section .name {
  /*flex: 1 1 0;*/
  width: 100%;
  font-size: 15px;
  color: #333;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.name-section .detail {
  display: flex;
  margin-top: 10px;
}

.name-section .detail .link {
  width: 100%;
  font-size: 15px;
  color: #333;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.append-primary .el-select .el-input {
  width: 120px;
}

.append-primary .input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.grecaptcha-badge {
  display: none !important;
}
</style>
