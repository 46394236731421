export const URL_API = 'https://api.atosa.asia/api'

const i = URL_API

export const API = {
  // URL_API_SRV_1: `http://localhost:8998/api/service1`,
  // URL_API_SRV_2: `http://localhost:9889/api/service2`,
  // URL_API_SRV_4: `http://localhost:9885/api/service4`,
  // URL_API_SRV_4: `http://103.149.170.25:9885/api/service4`,
  URL_API_SRV_1: 'https://global.atosa.asia/api/service1',
  URL_API_SRV_2: 'https://global.atosa.asia/api/service2',
  URL_API_SRV_3: 'https://app.atosa.asia/api/analysis',
  URL_API_SRV_4: 'https://app.atosa.asia/api/service4',
  URL_API_SRV_5: 'https://app.atosa.asia/api/service5',
  URL_API_SRV_8: 'https://app.atosa.asia/api/atospy',
  URL_API_SRV_8_STATIC: 'https://app.atosa.asia/api/static',
  // URL_API_SRV_8: 'http://localhost:9191/api/atospy',
  // URL_API_SRV_8_STATIC: 'http://localhost:9191/api/atospy/static',

  create_order_payment: `${URL_API}/v1/payment/order`,

  marketing: {
    keyword_file: {
      add: `${URL_API}/v1/shop-manager/marketing/keyword-file`,
      list: `${URL_API}/v1/shop-manager/marketing/keyword-file`,
      detail: `${URL_API}/v1/shop-manager/marketing/keyword-file/_id`,
      update: `${URL_API}/v1/shop-manager/marketing/keyword-file/_id`,
      delete: `${URL_API}/v1/shop-manager/marketing/keyword-file/_id`
    }
  },
  marketing_get_suggest: `${URL_API}/input/suggest/_keyword`,

  get_mipee_order_detail: `${URL_API}/v1/payment/order/_id`,
  encrypt_order_payload: `${URL_API}/make/place-order-payload/encrypt`,
  decrypt_order_payload: `${URL_API}/make/place-order-payload/decrypt`,
  store_buy_mipoint: `${URL_API}/store-account/buy-point`,
  feature_list: `${URL_API}/shop-manager/license/feature`,
  store_get_my_license: `${URL_API}/shop-manager/license/me`,
  store_account__login: `${URL_API}/shop-manager/sign`,
  store_account__change_password: `${URL_API}/store-account/change-password`,
  store_account__change_profile: `${URL_API}/store-account/change-profile`,
  shopee_manager__add_shop: `${URL_API}/shop-manager/shop`,
  shopee_manager__update_shop: `${URL_API}/shop-manager/shop`,
  shopee_manager__get_shop: `${URL_API}/shop-manager/shop`,
  shopee_manager__delete_shop: `${URL_API}/shop-manager/shop/_id/delete`,
  update_shop_config: 'https://sale.sieuloinhuan.net/api/shop-manager/_shop_id/',
  change_logistic_channel: `${URL_API}/store/change-logistic`,
  profile: `${URL_API}/shop-manager/me`,
  add_created_account: `${URL_API}/shop-manager/account`,
  get_created_account: `${URL_API}/shop-manager/account`,
  product_category_add: `${URL_API}/product-category`,
  product_category_get: `${URL_API}/product-category`,
  product_category_update: `${URL_API}/product-category`,
  product_category_delete: `${URL_API}/product-category/cat_id`,
  get_shop_product: `${URL_API}/product/_shop_id/products`,
  product_set_category: `${URL_API}/product-category/product`,
  check_order_name_phone_conflict: `${URL_API}/store-account/check-conflict`,
  product_get_list: `${URL_API}/product-category/product`,
  category_add_tag: `${URL_API}/product-category/cat_id/tags`,
  product_remove_cat: `${URL_API}/product-category/product/product_id/delete`,
  get_created_order: `${URL_API}/store/orders`,
  proxy_get_list: `${URL_API}/proxy`,
  get_user_info: `${URL_API}/account-buyer/`,
  order_change_evaluted_status: `${URL_API}/store-account/change-evaluate`,
  check_window_session: `${URL_API}/order-alert/`,
  window_session_ping: `${URL_API}/order-alert`,
  update_window_session_config: `${URL_API}/order-alert`,
  shop_order_sync: `${URL_API}/shop-order/sync`,
  change_shop_mapping_status: `${URL_API}/shop-manager/shop/_id/mapping`,
  change_shop_sidebar_status: `${URL_API}/shop-manager/shop/_id/sidebar`,
  shop_product_sync: `${URL_API}/product-category/product/sync`,
  shop_get_multi_config: `${URL_API}/store/multi-config`,
  shop_set_up_product: `${URL_API}/shop-manager/shop/_id/up-product`,
  shop_update_multi_config: `${URL_API}/store/multi-config`,
  make_csrf_token: `${URL_API}/make/shopee/csrf-token`,
  make_device_id: `${URL_API}/make/shopee/device-id`,
  make_password: `${URL_API}/make/shopee/password/`,
  make_password_v2: `${URL_API}/make/shopee/password`,
  spam_message_user_list: `${URL_API}/spam-message/user`,
  spam_message_user_add: `${URL_API}/spam-message/user`,
  spam_message_user_put: `${URL_API}/spam-message/user/_user_id`,
  spam_message_user_delete: `${URL_API}/spam-message/user/_user_id`,
  spam_message_file_add: `${URL_API}/spam-message/file`,
  spam_message_file_list: `${URL_API}/spam-message/file`,
  spam_message_file_delete: `${URL_API}/spam-message/file/_id`,
  spam_message_file_detail: `${URL_API}/spam-message/file/_id`,
  spam_message_file_update: `${URL_API}/spam-message/file/_id`,
  spam_message_file_delete_target: `${URL_API}/spam-message/file/_id/_target_id`,
  account_register_v2_add: `${URL_API}/account-register/`,
  account_register_v2_count: `${URL_API}/account-register/count`,
  account_register_v2_get: `${URL_API}/account-register/`,
  account_register_v2_delete: `${URL_API}/account-register/delete`,
  account_buyer_add: `${URL_API}/account-buyer/add`,
  account_buyer_delete: `${URL_API}/account-buyer/delete`,
  account_buyer_repository_get_config: `${URL_API}/store/buy/account`,
  account_buyer_repository_buy_account: `${URL_API}/store/buy/account`,
  account_buyer_address: `${URL_API}/account-buyer/address`,
  follow_by_me: `${URL_API}/fl-invite/me`,
  account_buyer_get_list: `${URL_API}/account-buyer/list`,
  account_buyer_full: `${URL_API}/account-buyer`,
  account_buyer_delete_multi: `${URL_API}/account-buyer/delete-multi`,
  account_buyer_delete_all: `${URL_API}/account-buyer/delete-all`,
  version: `${URL_API}/version/_version_no`,
  product_repository_add: `${URL_API}/shop-manager/product-repository`,
  account_buyer_to_traffic_mode: `${URL_API}/account-buyer/to-traffic-mode`,
  account_buyer_get_traffic_mode: `${URL_API}/account-buyer/traffic-mode`,
  account_buyer_change_active_status: `${URL_API}/account-buyer/activate`,
  traffic_invite_add: `${URL_API}/fl-invite`,
  traffic_invite_delete: `${URL_API}/fl-invite/delete`,
  job_get_list: `${URL_API}/v1/store/traffic/job`,
  job_get_worker_available: `${URL_API}/v1/store/traffic/worker`,
  job_commit: `${URL_API}/v1/store/traffic/commit`,
  job_commit_worked_user: `${URL_API}/v1/store/traffic/worked-user`,
  url_mipee_web: `${URL_API}/input/sign/handle`,
  upload_image: `${URL_API}/upload-image`,
  store_get_bought_package: `${URL_API}/v1/store/traffic-package/me/bought?channel=shopee_marketing_international,shopee_marketing,shopee_marketing_analysis,shopee_agency,shopee_marketing_survey`,
  store_set_bought_package: `${URL_API}/v1/store/traffic-package/me/bought/_id/apply`,

  input_order_directory_add: `${URL_API}/input-order/directory`,
  input_order_directory_list: `${URL_API}/input-order/directory`,
  input_order_directory_set_default: `${URL_API}/input-order/directory/default`,
  input_order_directory_enable: `${URL_API}/input-order/directory/enable`,
  input_order_directory_detail: `${URL_API}/input-order/directory/_id/detail`,
  input_order_directory_comment: `${URL_API}/input-order/directory/_id/comment`,
  input_order_directory_comment_add: `${URL_API}/input-order/directory/_id/comment`,
  input_order_directory_comment_count: `${URL_API}/input-order/directory/_id/comment-count`,
  input_order_directory_update: `${URL_API}/input-order/directory/_id/update`,
  input_order_directory_user_access: `${URL_API}/input-order/directory/_id/user-access`,
  input_order_directory_delete: `${URL_API}/input-order/directory/_id/delete`,
  input_order_dir_add_order: `${URL_API}/input-order/directory/_id/order`,
  store_get_current_package: `${URL_API}/store/product-pack`,
  get_system_package_available: `${URL_API}/v1/system/traffic-package/user?channel=shopee_marketing`,
  get_system_package_available_shopee_marketing: `${URL_API}/v1/system/traffic-package/user?channel=shopee_marketing_international`,
  get_system_package_available_shopee_marketing_analysis: `${URL_API}/v1/system/traffic-package/user?channel=shopee_marketing_analysis`,
  get_system_package_available_shopee_marketing_survey: `${URL_API}/v1/system/traffic-package/user?channel=shopee_marketing_survey`,
  store_buy_pack: `${URL_API}/v1/store/traffic-package/buy`,
  MIPEE_TRACKING: `${URL_API}/v1/mipee/tracking`,
  login: i + '/input/sign/login',
  shopee_get_product: i + '/input/product',
  update_order: i + '/input-order/directory/order/_id',
  update_order_max_fee_allow: i + '/input-order/directory/order/_id/change-fee-allow',
  export_directory_data: i + '/input-order/directory/_id/export-order',
  customer_get_list: i + '/order-input/customer',
  customer_export: i + '/order-input/customer/export',
  get_order_template_file: i + '/input-order/order-template',
  get_data_template_file: i + '/input-order/data-template',
  upload_file_and_detect: i + '/input-order/directory/_id/detect',
  upload_import_order: i + '/input-order/directory/_id/import',
  account_buyer_get: i + '/account-buyer/get-list',
  account_buyer_detail: i + '/account-buyer/_username',
  account_change_activate: i + '/account-buyer/activate',
  system_role_get_list: i + '/store/role',
  system_role_group_add: i + '/store/group',
  system_role_group_list: i + '/store/group',
  system_role_group_delete: i + '/store/group/_id',
  system_role_group_detail: i + '/store/group/_id',
  system_role_group_update: i + '/store/group/_id',
  system_user_list: i + '/store/user',
  system_user_add: i + '/store/user',
  system_user_update: i + '/store/user/_id',
  input_order_dir_add_data: i + '/input-order/directory/_id/data',
  input_data_delete: i + '/input-order/directory/order/_id',
  input_data_update: i + '/input-order/directory/_dir/data/_id',
  get_history: i + '/store/history',
  x_product_category_add: i + '/input-order/x-product/category',
  x_product_category_list: i + '/input-order/x-product/category',
  x_product_category_update: i + '/input-order/x-product/category/_id',
  x_product_add: i + '/input-order/x-product/category_id/product',
  x_product_delete: i + '/input-order/x-product/category_id/product/product_id',
  x_product_update: i + '/input-order/x-product/category_id/product/product_id',
  vtp_get_rollback_list: i + '/input/vtp/rollback',
  vtp_rollback_put_status: i + '/input/vtp/rollback/',
  vtp_sync_bill: i + '/input/vtp/bill/',
  vtp_sync_bill_re_get: i + '/input/vtp/bill/',
  vtp_sync_bill_initial: i + '/input/vtp/bill/shopee',
  get_shipping_fee: i + '/input/shopee/shipping-fee',
  register_check_ip: i + '/input/sign/check-ip',
  register_final: i + '/input/sign/register',
  payment_nganluong_create: i + '/v1/payment/nganluong/create',
  order_create_order: i + '/store-account/create-order',
  store_rating_comment_add: i + '/store-rate/',
  store_rating_comment_list: i + '/store-rate/',
  store_rating_comment_delete: i + '/store-rate/delete'
}

export const SuccessMessageDefault = 'Thao tác thành công!'
export const ErrorMessageDefault = 'Đã xảy ra sự cố, vui lòng kiểm tra kết nối mạng hoặc báo cáo với quản trị viên!'
