import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from '../views/home'
import PricingComponent from '../views/pricing'
import SavedComponent from '../views/saved'
import TiktokComponent from '../views/tiktok'
import ProfileComponent from '../views/profile'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: HomeComponent
  },
  {
    path: '/home',
    name: 'home',
    component: HomeComponent
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingComponent
  },
  {
    path: '/saved',
    name: 'saved',
    component: SavedComponent
  },
  {
    path: '/detail/tiktok/:id',
    name: 'detail-tiktok',
    component: TiktokComponent
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileComponent
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes
})

export default router
