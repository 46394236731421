<template>
  <div style="padding-top: 0.5rem">
    <div class="container">
      <div>
        <strong>
          {{ $t('video_detail') }}
        </strong>
      </div>

      <div class="detail-container">
        <div class="video-section">
          <div class="header-section">
            <div class="avatar">
              <img :src="data.avatarPath ? $api.URL_API_SRV_8_STATIC + data.avatarPath : data.author?.avatarThumb"
                   alt="test">
            </div>
            <div class="title" @click="open_link(`https://www.tiktok.com/@${data.author?.uniqueId}`)">
              {{ data.author?.nickname }}

              <div style="margin-top: 5px; color: #000">
                <a :href="`https://www.tiktok.com/@${data.author?.uniqueId}`" target="_blank">
                  {{ `https://www.tiktok.com/@${data.author?.uniqueId}` }}
                </a>
              </div>
            </div>
          </div>
          <div class="video">
            <img :src="data.imagePath ? data.URL_API_SRV_8_STATIC + data.imagePath : data.video?.cover"
                 alt="test" class="filter">
            <video controls="controls" controlslist="nodownload" oncontextmenu="return false"
                   :src="data.videoPath ? $api.URL_API_SRV_8_STATIC + data.videoPath : data.video?.playAddr"></video>
          </div>
          <div class="name-section">
            <div class="name">
              {{ data.desc }}
            </div>
            <div class="detail">
              <div class="link">
                <a :href="`https://m.tiktok.com/v/${data.id || data.tiktokId}.html`" target="_blank">
                  https://m.tiktok.com/v/{{ data.id || data.tiktokId }}.html
                </a>
              </div>
            </div>
          </div>
          <div style="margin-top: 30px; text-align: center">
            <el-button type="primary" size="medium" @click="m__download_video">
              {{ $t('download_video') }}
            </el-button>
          </div>
        </div>
        <div class="info-section">
          <div class="metrics">
            <div class="metrics-item">
              <div>Views</div>
              <div><strong style="font-size: 22px">{{ short_value(data.stats?.playCount) }}</strong></div>
            </div>
            <div class="metrics-item">
              <div>Likes</div>
              <div><strong style="font-size: 22px">{{ short_value(data.stats?.diggCount) }}</strong></div>
            </div>
            <div class="metrics-item">
              <div>Comments</div>
              <div><strong style="font-size: 22px">{{ short_value(data.stats?.commentCount) }}</strong></div>
            </div>
            <div class="metrics-item">
              <div>Shares</div>
              <div><strong style="font-size: 22px">{{ short_value(data.stats?.shareCount) }}</strong></div>
            </div>
          </div>
          <div class="content">
            <table>
              <tr>
                <td>
                  <div>{{ $t('platform') }}:</div>
                </td>
                <td>
                  <div>Tiktok</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>{{ $t('description') }}:</div>
                </td>
                <td>
                  <div>
                    {{ data.desc }}
                    &nbsp;&nbsp;
                    <i class="el-icon-copy-document" style="cursor: pointer" @click="m__copy_text(data.desc)"></i>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Hashtag:</div>
                </td>
                <td>
                  <div style="display: flex; flex-wrap: wrap">
                    <a v-for="h in data.challenges?.map(i => i.title)" :key="h" :href="`/?hashtag=${h}`"
                       target="_blank" style="margin-right: 20px">
                      #{{ h }}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>{{ $t('video_location') }}:</div>
                </td>
                <td>
                  <div>
                    <el-tooltip placement="top" content="Vietnam">
                      <img style="width: 40px; height: 40px" src="@/assets/flags/vi.png" alt="flag">
                    </el-tooltip>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>{{ $t('author_link') }}:</div>
                </td>
                <td>
                  <div>
                    <a :href="`https://www.tiktok.com/@${data.author?.uniqueId}`" target="_blank">
                      {{ `https://www.tiktok.com/@${data.author?.uniqueId}` }}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>Video Link:</div>
                </td>
                <td>
                  <div>
                    <a :href="`https://m.tiktok.com/v/${data.id || data.tiktokId}.html`" target="_blank">
                      https://m.tiktok.com/v/{{ data.id || data.tiktokId }}.html
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>{{ $t('released_date') }}:</div>
                </td>
                <td>
                  <div>{{ data.createTime * 1000 | moment('DD/MM/YYYY') }}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>{{ $t('updated_time') }}:</div>
                </td>
                <td>
                  <div>
                    {{ data.report ? data.report[0]?.time : '' | moment('DD/MM/YYYY') }}
                    -
                    {{ data.report ? data.report[data.report?.length - 1]?.time : '' | moment('DD/MM/YYYY') }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="report-container">
        <div class="title">
          <strong>
            <i class="el-icon-s-data"></i> Data Report
          </strong>
        </div>
        <div class="chart">
          <LogChart style="height: 200px; width: 100%" :reports="data.report"></LogChart>
        </div>
      </div>

      <div class="report-container">
        <div class="title">
          <strong>
            <i class="el-icon-time"></i> Data History
          </strong>
        </div>
        <div class="chart">
          <div>
            <el-table :data="data.report?.filter(i => !i.default)">
              <el-table-column label="Video text" prop="desc" min-width="300px"></el-table-column>
              <el-table-column label="Views" prop="playCount">
                <template slot-scope="scope">
                  {{ short_value(scope.row.playCount) }}
                </template>
              </el-table-column>
              <el-table-column label="Likes" prop="diggCount">
                <template slot-scope="scope">
                  {{ short_value(scope.row.diggCount) }}
                </template>
              </el-table-column>
              <el-table-column label="Comments" prop="commentCount">
                <template slot-scope="scope">
                  {{ short_value(scope.row.commentCount) }}
                </template>
              </el-table-column>
              <el-table-column label="Shares" prop="shareCount">
                <template slot-scope="scope">
                  {{ short_value(scope.row.shareCount) }}
                </template>
              </el-table-column>
              <el-table-column label="Time" prop="time">
                <template slot-scope="scope">
                  {{ scope.row.time | moment('DD/MM/YYYY') }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogChart from '@/components/log-chart'

export default {
  name: 'tiktok-video-component',

  components: {
    LogChart
  },

  data () {
    return {
      data: {}
    }
  },

  async beforeMount () {
    console.log(this.$route.params.id)
    await this.m__get_data_detail(this.$route.params.id)
  },

  methods: {
    m__copy_text (text) {
      navigator.clipboard.writeText(text)
      this.$message({
        message: this.$t('copied').toString(),
        type: 'success'
      })
    },

    m__download_video () {
      if (!this.data.videoPath || !this.data.video.playAddr) {
        return
      }

      fetch(this.data.videoPath ? this.$api.URL_API_SRV_8_STATIC + this.data.videoPath : this.data.video?.playAddr)
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          // the filename you want
          a.download = `tvideo-${this.data.id || this.data.tiktokId}.mp4`
          document.body.appendChild(a)
          a.click()
          window.URL.revokeObjectURL(url)
        })
        .catch(console.error)
    },

    async m__get_data_detail (item_id) {
      if (!this.$store.state.token) {
        this.EB.$emit('require_login')
        return
      }

      if (!item_id) {
        return this.$router.push('/')
      }

      try {
        const response = await this.$http.get(this.$api.URL_API_SRV_8 + '/tiktok/detail', {
          params: { item_id: item_id },
          ...this.cf()
        })
        console.log(response)
        this.data = response.data.data
      } catch (e) {
        console.log(e)
        this.m__handle_error(e)
      }
    },

    m__handle_error (error) {
      if (error.data && error.data.code && error.data.code === 81) {
        // this.EB.$emit('require_license')
        this.$router.push('/pricing?msg=upgrade_license')
      } else {
        this.ntf_w(error)
      }
    }
  }
}
</script>

<style>
.detail-container {
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
}

.detail-container .video-section {
  width: 35%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 20px;
  margin-right: 20px;
}

.detail-container .video-section .desc {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.detail-container .video-section .video {
  width: 100%;
  height: 420px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.detail-container .video-section .video .filter {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(10px);
  z-index: 888;
  object-fit: cover;
}

.detail-container .video-section .video video {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 889;
}

.detail-container .video-section .header-section {
  display: flex;
  padding: 5px 5px 5px 0;
  align-items: center;
}

.detail-container .video-section .header-section .avatar {
  width: 40px;
}

.detail-container .video-section .header-section .avatar img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  object-fit: fill;
}

.detail-container .video-section .header-section .title {
  width: calc(100% - 50px);
  font-size: 15px;
  font-weight: 600;
  padding: 0 10px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

/*.detail-container .video-section .header-section .title:hover {*/
/*  text-decoration: underline;*/
/*  text-underline-offset: 5px;*/
/*}*/

/* todo: ... */
.detail-container .info-section {
  width: 65%;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 20px;
}

.detail-container .info-section .metrics {
  display: flex;
  width: 100%;
  justify-content: space-around;
  vertical-align: baseline;
}

.detail-container .info-section .metrics .metrics-item {
  flex: 1;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 12%);
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 16px 5px 16px 16px;
  background: #fff;
  margin-right: 15px;
  cursor: pointer;
}

.detail-container .info-section .metrics .metrics-item:last-child {
  margin-right: 0;
}

.detail-container .info-section .content {
  margin-top: 40px;
  padding-left: 2px;
  font-size: 16px;
}

.detail-container .info-section .content table tr td div {
  padding-bottom: 30px;
}

.detail-container .info-section .content table tr td:first-child {
  padding-right: 20px;
  vertical-align: text-top;
  min-width: 150px;
}

.detail-container .info-section .content table tr td:last-child {
  font-weight: 600;
  vertical-align: text-top;
}

/* todo: ... */
.report-container {
  width: 100%;
  margin-bottom: 40px;
}

.report-container .title {
}

.report-container .chart {
  min-height: 100px;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 20px 10px;
}
</style>
