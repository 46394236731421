import axios from 'axios'
import store from '../store'
import { API, ErrorMessageDefault } from '@/config'

export const http = axios

http.interceptors.response.use(null, async function (error) {
  if (!error.response) {
    return Promise.reject({ data: { ErrorMessage: ErrorMessageDefault } })
  }

  if (error.response.status === 401) {
    await store.dispatch('set__user_info', {})
    await store.dispatch('set__token', '')
    location.reload()
    return
  }

  return Promise.reject(error.response)
})

export default {
  install (Vue) {
    Vue.prototype.$api = API
    Vue.prototype.$http = http
  }
}
