import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  vi: {
    hi: 'Xin chào',
    active: 'Hoạt động',
    balance: 'Số dư',
    pack: 'Gói',
    package: 'Gói sản phẩm',
    lang: 'Ngôn ngữ',
    account: 'Thông tin tài khoản',
    update: 'Cập nhật',
    update_successfully: 'Cập nhật thành công',
    login: 'Đăng nhập',
    register: 'Đăng ký',
    register_now: 'Đăng ký ngay',
    send_request: 'Gửi yêu cầu',
    exist_account: 'Đã có tài khoản, Đăng nhập ngay',
    invalid_email_format: 'Định dạng email không hợp lệ',
    password_length: 'Mật khẩu phải chứa ít nhất {length} ký tự',
    password_match: 'Mật khẩu xác nhận không khớp',
    change_password: 'Đổi mật khẩu',
    logout: 'Đăng xuất',
    quantity: 'Số lượng',
    product: 'Sản phẩm',
    duration: 'Thời hạn',
    price: 'Đơn giá',
    start_time: 'Từ ngày',
    end_time: 'Đến ngày',
    day: 'Ngày',
    week: 'Tuần',
    month: 'Tháng',
    year: 'Năm',
    hour: 'Giờ',
    minute: 'Phút',
    second: 'Giây',
    hours: 'Giờ',
    minutes: 'Phút',
    seconds: 'Giây',
    close: 'Đóng',
    attribute: 'Thuộc tính',
    value: 'Giá trị',
    yes: 'Có',
    no: 'Không',
    trial: 'Dùng thử',
    package_name: 'Tên gói',
    description: 'Mô tả',
    note: 'Lưu ý',
    action: 'Hành động',
    option: 'Tùy chọn',
    proxy_status: 'Trạng thái proxy',
    created_at: 'Tạo lúc',
    default: 'Mặc định',
    delete: 'Xóa',
    region: 'Quốc gia',
    select: 'Chọn',
    all: 'Tất cả',
    paid: 'Đã bán',

    warning: 'Chú ý',
    error: 'Lỗi',
    success: 'Thành công',
    confirm: 'Chắc chắn',
    cancel: 'Hủy',
    successfully: 'Thao tác thành công!',
    system_error: 'Hệ thống đang trong quá trình cập nhật. Mong quý khách chờ trong giây lát!',

    display_name: 'Họ và tên',
    username: 'Tên đăng nhập',
    password: 'Mật khẩu',
    current_password: 'Mật khẩu hiện tại',
    new_password: 'Mật khẩu mới',
    confirm_password: 'Nhập lại mật khẩu',
    forgot_password: 'Bạn quên mật khẩu',
    phone_number: 'Số điện thoại',
    email: 'Địa chỉ Email',

    login_title: 'ĐĂNG NHẬP ATOSA',
    login_sub_title: 'Phần mềm Shopee nhiều người dùng nhất',
    login_successfully: 'Đăng nhập thành công',

    register_title: 'ĐĂNG KÝ ATOSA',
    register_sub_title: 'Phần mềm Shopee nhiều người dùng nhất',
    register_note: 'Mật khẩu sẽ được gửi tới email của bạn.',
    register_ban_ip: 'Có thể IP của bạn đã bị chặn hãy tắt trình chặn quảng cáo và thử lại hoặc liên hệ nhân viên hỗ trợ.',
    register_confirm: 'Mật khẩu của bạn sẽ được gửi đến {email}. Bạn đã nhập đúng email của mình chưa?',
    register_successfully: 'Gửi yêu cầu đăng ký thành công, vui lòng kiểm tra hòm thư của bạn',
    register_invalid_captcha: 'Captcha không hợp lệ',
    register_account_exists: 'Địa chỉ email đã được sử dụng để đăng ký trước đó, vui lòng kiểm tra và thử lại sau!',

    input_display_name: 'Nhập tên đăng nhập',
    input_username: 'Nhập tên đăng nhập',
    input_password: 'Nhập mật khẩu',
    input_current_password: 'Nhập mật khẩu hiện tại',
    input_phone_number: 'Nhập số điện thoại',
    input_email: 'Nhập địa chỉ email',
    input_new_password: 'Nhập mật khẩu mới',
    input_confirm_password: 'Nhập mật khẩu xác nhận',
    input_voucher: 'Nhập mã quà tặng / voucher',
    input_keyword: 'Nhập từ khóa tìm kiếm',
    input_otp: 'Vui lòng nhập mã OTP',

    shop_error_perm: 'Tài khoản hoặc mật khẩu không đúng!',
    shop_error_banned: 'Tài khoản đã bị khóa, vui lòng kiểm tra và thử lại sau!',
    shop_error_require_captcha: 'Yêu cầu nhập mã captcha! Vui lòng đăng nhập thành công trên trình duyệt sau đó đăng nhập lại trên Atosa!',
    shop_error_too_frequent: 'Yêu cầu nhập mã captcha! Vui lòng đăng nhập thành công trên trình duyệt sau đó đăng nhập lại trên Atosa!',
    shop_error_captcha: 'Captcha không đúng, vui lòng thử lại sau!',
    shop_error_notfound: 'Tài khoản không tồn tại, vui lòng kiểm tra và thử lại sau!',
    shop_error_need_otp: 'Yêu cầu OTP, vui lòng vào phần cửa hàng và Đăng nhập lại!',
    shop_error_need_ivs: 'Vui lòng xác thực OTP trên thiết bị điện thoại trên Shopee!',
    shop_error_info: 'Không thể đăng nhập shop! Vui lòng thông báo với nhân viên để hỗ trợ!',
    shop_otp_required: 'Yêu cầu OTP, vui lòng xác nhận otp',

    server_wrong_account: 'Tài khoản hoặc mật khẩu không đúng, vui lòng kiểm tra và thử lại sau!',

    forget_password: 'Quên mật khẩu',
    forgot_password_title: 'Khôi phục mật khẩu',
    forgot_password_note: 'Chúng tôi sẽ gửi yêu cầu reset mật khẩu về địa chỉ email của bạn',
    forgot_back_to_login: 'Trở lại Đăng nhập',
    forgot_send_request_successfully: 'Gửi yêu cầu thành công',
    forgot_new_password_title: 'CÀI ĐẶT MẬT KHẨU MỚI',
    forgot_new_password_greeting: 'Chào {name}, hãy cài đặt mật khẩu mới cho tài khoản {email}',

    payment: 'Thanh toán',
    atosa_package: 'Gói sản phẩm Atosa',
    recharge: 'Nạp tiền',
    atosa_course: 'Khóa học Shopee Ads',
    management: 'Quản lý',
    shopee_shop: 'Cửa hàng Shopee',
    shop_center: 'Phân tích bán hàng',
    shop_product: 'Sản phẩm của shop',
    atosa_tool: 'Công cụ Atosa',
    seo_position: 'Kiểm tra vị trí Seo',
    keyword_tool: 'Công cụ từ khóa',
    shop_analysis: 'Phân tích đối thủ',
    spoil_ads: 'Spoil vị trí Quảng cáo',
    shop_survey: 'Khảo sát thị trường',
    keyword_ads: 'Quảng cáo tìm kiếm',
    auto_ads: 'Quảng cáo tự động',
    anti_click_log: 'Báo cáo click ảo',
    check_ads_position: 'Kiểm tra vi trí quảng cáo',
    guideline: 'Hướng dẫn sử dụng',
    customer_support: 'Kênh hỗ trợ khách hàng',
    anti_click: 'Chống click ảo',
    shop_connect: 'Shop kết nối',
    delete_keyword: 'Xóa từ khóa',
    delete_keywords: 'Xóa từ khóa',
    using_package: 'Gói sản phẩm đang dùng',
    bought_package: 'Gói sản phẩm đã mua',
    license_to: 'Bản quyền đến',
    apply_now: 'Áp dụng ngay',
    buy_package: 'Mua gói',
    payments: 'Hình thức thanh toán',
    online_payment: 'Thanh toán trực tuyến',
    used_package_has: 'Gói đang dùng còn',
    confirm_buy: 'Xác nhận mua',
    voucher_info: 'Thông tin Mã giảm giá / Voucher',
    discount_for_bill: 'Giảm {value}% cho tổng giá trị đơn hàng',
    discount_for_package: 'Áp dụng cho gói',
    discount_minimize: 'Giảm tối đa',
    discount_minimum_order: 'Đơn hàng tối thiểu',
    total_order: 'Tổng tiền',
    see_feature: 'Xem tính năng',
    using: 'Đang dùng',
    see_detail: 'Xem chi tiết',
    package_is_expr: 'Gói Shopee Marketing của quý khách đã hết hạn. Vui lòng gia hạn gói để tiếp tục sử dụng.',
    confirm_apply_package_1: 'Bạn có chắc muốn áp dụng gói {package} [Kênh {channel}] ngay không?',
    confirm_apply_package_2: 'Bạn có chắc muốn áp dụng gói {package} [Kênh {package}] ngay không? Gói bạn đang sử dụng sẽ được cộng thêm 1 tháng, bạn vẫn muốn tiếp tục?',
    confirm_apply_package_3: 'Bạn có chắc muốn áp dụng gói {package} [Kênh {package}] ngay không? Nếu bạn áp dụng gói này, gói {current_package} bạn đang dùng sẽ bị mất, bạn vẫn muốn tiếp tục?',
    confirm_buy_package: 'Bạn có chắc muốn mua gói {package} x {month} tháng với giá {total_order} không? Bạn sẽ không được hoàn tiền hay cộng dồn các chỉ số giới hạn!',
    balance_not_enough: 'Số dư hiện tại {balance} không đủ để thực hiện giao dịch, bạn có muốn nạp tiền ngay không?',
    apply_for_1_month: 'Gói áp dụng cho 1 tháng',
    dub_click_for_detail: 'Click đúp để xem chi tiết',
    invalid_voucher: 'Mã giảm giá / Voucher không được áp dụng cho Atosa',

    shop_list: 'Danh sách cửa hàng',
    add_shop: 'Thêm shop',
    shop_note_1: 'Quý khách vui lòng thông báo với bộ phận CSKH để được đổi tên cửa hàng.',
    shop_note_2: 'Chọn cửa hàng mặc định để tự động chọn cửa hàng khi sử dụng các tính năng của Atosa.',
    shop_note_3: 'Hiện tại, Atosa đã hỗ trợ bật OTP của shop (Qua SMS hoặc Xác thực qua App Shopee).',
    shop_note_4: 'Khi quý khách bật OTP, vui lòng đăng nhập lại ở trên Atosa.',
    re_login: 'Đăng nhập lại',
    config_proxy: 'Cấu hình proxy',
    test_proxy: 'Test proxy',
    delete_proxy: 'Xóa proxy',
    delete_shop: 'Xóa shop',
    account_link: 'Liên kết tài khoản',
    account_linked: 'Đã liên kết tài khoản',
    re_link_account: 'Click để liên kết lại tài khoản',
    using_system_proxy: 'Đang sử dụng proxy hệ thống',
    save_config: 'Lưu cấu hình',
    default_selected_shop: 'Đã chọn cửa hàng mặc định',
    confirm_delete_shop: 'Bạn có chắn chắn muốn xóa cửa hàng này không? Mọi cấu hình tự động sẽ bị xóa.',
    please_verify_otp: 'Please verify OTP on your mobile device!',
    re_login_error: 'Đã có lỗi xảy ra khi đăng nhập lại',
    invalid_region: 'Quốc gia lựa chọn không hợp lệ',
    proxy_select: 'Vui lòng chọn loại Proxy',
    proxy_server: 'Vui lòng nhập Proxy Server',
    proxy_port: 'Vui lòng nhập Proxy Port',
    proxy_connected: 'Kết nối Proxy thành công',
    invalid_proxy: 'Proxy không hợp lệ',
    delete_proxy_confirm: 'Bạn có chắn muốn xóa proxy này không?',
    delete_proxy_successfully: 'Xóa proxy thành công',

    select_shop: 'Chọn cửa hàng',
    added_shop: 'Cửa hàng đã thêm',
    sub_shop: 'Cửa hàng phụ trách',
    select_time: 'Chọn thời gian',
    select_start_time: 'Chọn thời gian bắt đầu',
    select_end_time: 'Chọn thời gian kết thúc',
    business_insight: 'Phân tích bán hàng',
    date_period: 'Khung thời gian',
    real_time: 'Real time',
    today_period: 'Tới {hour} hôm nay',
    vs_today_period: 'so với 00:00-{hour}:00 hôm qua',
    today: 'Hôm nay',
    yesterday: 'Hôm qua',
    vs_yesterday: 'so với hôm qua',
    past_days: 'Trong {day} ngày qua',
    vs_past_days: 'so với {day} ngày trước',
    by_day: 'Theo ngày',
    vs_by_day: 'so với hôm trước',
    by_week: 'Theo tuần',
    vs_by_week: 'so với tuần trước',
    by_month: 'Theo tháng',
    vs_by_month: 'So với tháng trước',
    by_year: 'Theo năm',
    vs_by_year: 'so với năm trước',
    refresh_data: 'Làm mới dữ liệu',
    reload_data: 'Tải lại dữ liệu',
    loading_data: 'Đang tải dữ liệu',
    all_ads: 'Tất cả Quảng cáo',
    search_ads: 'Quảng cáo Từ khóa',
    discovery_ads: 'Quảng cáo Khám phá',
    select_metrics: 'Chọn chỉ số',
    metrics: 'Chỉ số',

    impression: 'Lượt xem',
    clicks: 'Lượt click',
    ctr: 'Tỉ lệ click',
    expense: 'Chi phí',
    conversions: 'Lượt chuyển đổi',
    items_sold: 'Sản phẩm đã bán',
    conversion_rate: 'Tỉ lệ chuyển đổi',
    cost_per_conversion: 'Chi phí chuyển đổi',
    direct_conversions: 'Lượt chuyển đổi trực tiếp',
    direct_items_sold: 'Sản phẩm đã bán trực tiếp',
    cpc: 'CPC',
    gmv: 'GMV',
    direct_gmv: 'GMV trực tiếp',
    roi: 'ROI',
    direct_roi: 'ROI trực tiếp',
    cir: 'CIR',
    direct_cir: 'CIR trực tiếp',
    direct_conversion_rate: 'Tỉ lệ chuyển đổi trực tiếp',
    cost_per_direct_conversion: 'Chi phí chuyển đổi trực tiếp',
    budget: 'Ngân sách',
    shop_metrics: 'Chỉ số Shop',
    use_ads_expense: 'Sử dụng chi phí Quảng cáo',
    all_order: 'Tất cả đơn hàng',
    placed_order: 'Đơn đã xác nhận',
    paid_order: 'Đơn đã thanh toán',
    export_data: 'Tải dữ liệu',
    download_data: 'Tải dữ liệu',
    sales: 'Doanh số',
    orders: 'Đơn hàng',
    visitors: 'Lượt truy cập',
    page_views: 'Lượt xem trang',
    sales_per_order: 'Doanh thu / đơn',
    ads_metrics: 'Chỉ số Quảng cáo',
    minimize: 'Thu nhỏ',
    go_search_ads: 'Đi đến trang Quảng cáo Từ khóa',
    go_discovery_ads: 'Đi đến trang Quảng cáo Khám phá',
    chart: 'Biểu đồ',
    order_metrics: 'Chỉ số đơn hàng',
    buyer_info: 'Thông tin người mua',
    total_buyers: 'Tổng số người mua',
    new_buyers: 'Số người mua mới',
    existing_buyers: 'Số người mua lại',
    followers: 'Số người theo dõi',
    return_rate: 'Tỉ lệ quay lại',
    add_to_cart: 'Thêm vào giỏ',
    select_top_product: 'Chọn {number} sản phẩm đầu tiên',
    customer_segment: 'Phân khúc Khách hàng',
    by_price_segment: 'Theo phân khúc giá',
    buyer: 'Người mua',
    buyers: 'Số người mua',
    uncheck: 'Bỏ chọn',
    checked: 'Đã chọn',
    check: 'Chọn',
    create_new_ads: 'Tạo quảng cáo mới',
    connected_seller: 'Đã kết nối seller',
    connected_seller_fail: 'Đã có lỗi xảy ra khi kết nối seller',
    load_success: 'Tải dữ liệu thành công',
    selected_shop: 'Đã chọn cửa hàng mặc định',

    product_list: 'Danh sách sản phẩm',
    please_select_product: 'Vui lòng chọn 1 sản phẩm',
    please_select_time: 'Vui lòng chọn thời gian',
    an_error: 'Đã có lỗi xảy ra',
    chart_large_data: 'Không thể hiển thị toàn bộ biểu đồ vì dữ liệu quá lớn. Tính năng đang được cập nhật.',

    stock: 'Kho hàng ',
    product_note: 'Lưu ý: Sử dụng tính năng xem trước để kiểm tra tên sản phẩm trước khi cập nhật hàng loạt!',
    add_text: 'Thêm text trong tên sản phẩm',
    remove_text: 'Xóa text trong tên sản phẩm',
    preview_product: 'Xem trước sản phẩm',
    old_name: 'Tên cũ',
    new_name: 'Tên mới',
    input_add_text: 'Nhập đoạn text muốn thêm',
    input_remove_text: 'Nhập đoạn text muốn xóa',
    add_position: 'Vị trí thêm',
    in_front_name: 'Phía trước tên sản phẩm',
    behind_name: 'Phía sau tên sản phẩm',
    skip_preview: 'Bỏ qua xem trước',
    preview: 'Xem trước',
    other_option: 'Lựa chọn khác',
    case_sensitive: 'Phân biệt chữ viết hoa',
    delete_only_once: 'Chỉ xóa một lần',
    update_name_confirm: 'Bạn có chắc chắn muốn cập nhật tên sản phẩm không?',

    check_seo_position: 'Kiểm tra vị trí SEO Tìm Kiếm',
    check_seo_position_similar: 'Kiểm tra vị trí SEO Liên Quan',
    product_link: 'Link sản phẩm',
    input_product_link: 'Nhập link sản phẩm',
    select_region: 'Chọn quốc gia',
    max_position: 'Vị trí tối đa',
    keyword: 'Từ khóa',
    keywords: 'Số từ khóa',
    add_keyword: 'Thêm từ khóa',
    remove_keyword: 'Xóa từ khóa',
    order_by: 'Sắp xếp theo',
    relevancy: 'Liên quan',
    newest: 'Mới nhất',
    top_sales: 'Bán chạy',
    device: 'Thiết bị',
    search: 'Tìm kiếm',
    lookup: 'Tìm kiếm',
    click_to_stop: 'Click để dừng',
    stop: 'Dừng',
    searching_position: 'Vị trí đang tìm kiếm',
    display_position: 'Vị trí hiển thị',
    remove: 'Xóa',
    status: 'Trạng thái',
    waiting: 'Đang chờ',
    searching: 'Đang tìm kiếm',
    complete: 'Hoàn thành',
    completed: 'Đã hoàn thành',
    invalid_product_link: 'Link sản phẩm không hợp lệ',
    invalid_shop_link: 'Link shop không hợp lệ',
    ads_position: 'Vị trí Ads',

    keyword_file: 'Tệp từ khóa',
    saved_keyword: 'Từ khóa đã lưu',
    saved_keyword_file: 'Tệp từ khóa đã lưu',
    keyword_analysis: 'Phân tích từ khóa',
    select_top_keyword: 'Chọn {number} từ khóa đầu tiên',
    uncheck_all: 'Bỏ chọn tất cả',
    delete_selected_keyword: 'Xóa từ khóa đã chọn',
    add_selected_keyword: 'Thêm từ khóa đã chọn',
    show_current_keyword: 'Mở từ khóa hiện tại',
    show_keyword_tool: 'Mở công cụ từ khóa',
    filter: 'Bộ lọc',
    delete_filter: 'Xóa bộ lọc',
    compare: 'Đối chiếu',
    volume: 'Lượt tìm kiếm',
    volumes: 'Lượt tìm kiếm',
    shopee_volume: 'Lượt tìm kiếm Shopee',
    shopee_volumes: 'Lượt tìm kiếm Shopee',
    recommend_price: 'Giá thầu gợi ý',
    delete_all_keyword: 'Xóa tất cả từ khóa',
    import_excel: 'Import Excel',
    your_price: 'Giá của bạn',
    no_keyword: 'Bạn chưa chưa có từ khóa nào, hay thêm từ khóa',
    filename: 'Tên file',
    input_filename: 'Nhập tên file',
    save_file: 'Lưu file',
    not_shop: 'Bạn chưa liên kết bất kì tài khoản nào, vui lòng thêm shop và thử lại!',
    add: 'Thêm',
    added: 'Đã thêm',
    selected_keyword: 'Từ khóa đã chọn',
    selected_keywords: 'Từ khóa đã chọn',
    total_volumes: 'Tổng lượt tìm kiếm',
    total_shopee_volumes: 'Tổng lượt tìm kiếm Shopee',
    please_select_shop: 'Vui lòng chọn shop',
    not_for_trial: 'Tính năng này không áp dụng cho gói Trial',
    at: 'Lúc',
    save_keyword_file: 'Lưu file từ khóa',
    save_keyword_file_confirm: 'Bạn có muốn lưu file với tên: {name} không?',
    chart_showing_msg: 'Biểu đồ thể hiện lượt kiếm của từ khóa trên các thiết bị',
    getting_shopee_volumes: 'Đang lấy lượt tìm kiếm Shopee',
    updated_shopee_volumes: 'Đã cập nhật Lượt tìm kiếm Shopee và Giá thầu gợi ý',
    cannot_connect_fff: 'Không thể kết nối tài khoản, vui lòng thử lại sau!',
    column: 'Cột',
    column_name: 'Tên cột',
    expression: 'Biểu thức',
    relevance: 'Chất lượng',
    select_product: 'Chọn sản phẩm',
    execute: 'Thực hiện',
    cost: 'Chi phí',
    keyword_no_result: 'Không tìm thấy kết quả cho từ khóa: ',
    total: 'Tổng',
    no_product: 'Shop của bạn chưa có sản phẩm nào',
    keyword_file_list: 'Danh sách tệp từ khóa',
    created_by: 'Người tạo',
    no_data: 'Không có dữ liệu',
    no_data_yet: 'Chưa có dữ liệu',
    detail: 'Chi tiết',
    save_price: 'Giá đã lưu',
    save_volumes: 'Lượt tìm kiếm đã lưu',
    current_price: 'Giá hiện tại',
    current_volumes: 'Lượt tìm kiếm hiện tại',
    update_confirm: 'Bạn có chắc muốn cập nhật không?',
    update_file_confirm: 'Bạn có chắc muốn cạp nhật file này không?',
    loading_any_data_confirm: 'Đang tải dữ liệu, xin vui lòng chờ...',
    delete_any_data_confirm: 'Bạn có muốn xóa cấu hình này không?',
    delete_file_confirm: 'Bạn có muốn xóa file này không?',
    keyword_list: 'Danh sách từ khóa',
    add_to_current_keyword: 'Thêm vào [Từ khóa hiện tại]',
    keyword_file_updated: 'Đã cập nhật file tư khóa',
    keyword_file_deleted: 'Đã xóa file từ khóa',

    shop_analysis_note: 'Dữ liệu phân tích dựa trên những thông tin được hiển thị tại trang sản phẩm của Shop.',
    analyze: 'Phân tích',
    total_sold: 'Tổng đã bán',
    month_sold: 'Bán trong tháng',
    month_sales: 'Doanh số (Tháng)',
    month_sales_per_order: 'Doanh số tháng',
    total_views: 'Tổng lượt xem',
    product_name: 'Tên sản phẩm',
    discount: 'Discount',
    created_time: 'Thời gian tạo',
    day_number: 'Số ngày ',
    auto_config: 'Cấu hình tự động',
    auto_config_list: 'Danh sách cấu hình tự động',
    shop_link: 'Link shop',
    input_shop_link: 'Nhập link shop',
    saved_file_list: 'Tệp đã lưu',
    manual_save: 'Lưu thủ công',
    auto_save: 'Lưu tự động',
    shop_name: 'Tên shop',
    save_type: 'Kiểu lưu',
    file_detail: 'Chi tiết file',

    spoil_ads_position: 'Spoil Vị trí Quảng cáo',
    select_top_note: 'Note: Atosa sẽ chỉ hiển thị top {number}',
    ads_product_list: 'Danh sách sản phẩm Quảng cáo',
    total_reviews: 'Tổng review',
    reviews_orders: 'Reviews / Số lượt mua',
    ads_type: 'Loại quảng cáo',
    auto: 'Tự động',
    manual: 'Thủ công',
    keyword_type: 'Loại từ khóa',
    broad: 'Mở rộng',
    Exact: 'Chính xác',

    price_range: 'Khoảng giá',
    from: 'Từ',
    to: 'Đến',
    max_number: 'Số lượng tối đa',
    input_max_number: 'Nhập số lượng tối đa',
    category: 'Danh mục',
    category_level: 'Danh mục cấp {number}',
    products: 'Sản phẩm',
    sold: 'Đã bán',

    ongoing: 'Đang chạy',
    paused: 'Tạm dừng',
    ended: 'Kết thúc',
    ads_credit: 'Ngân sách Quảng cáo',
    guide_for_search_ads: 'Hướng dẫn chạy Quảng cáo Tìm kiếm',
    guide_for_discovery_ads: 'Hướng dẫn chạy quảng cáo Khám phá',
    bulk_budget_update: 'Cập nhật Ngân sách hàng loạt',
    batch_auto_install: 'Cài đặt tự động hàng loạt',
    update_max_price: 'Cập nhật giá thầu tối đa',
    loading_campaign_list: 'Đang tải danh sách chiến dịch',
    campaign_list: 'Danh sách chiến dịch',
    search_ads_detail: 'Chi tiết quảng cáo Sản phẩm',
    discovery_ads_detail: 'Chi tiết quảng cáo Khám phá',
    shop_ads_detail: 'Chi tiết quảng cáo Shop',
    back: 'Quay lại',
    deleted: 'Đã xóa',
    soldout: 'Hết hàng',
    no_limit: 'Không giới hạn',
    no_time_limit: 'Không giới hạn thời gian',
    type: 'Kiểu',
    daily_budget: 'Ngân sách hàng ngày',
    total_budget: 'Tổng ngân sách',
    pause: 'Tạm dừng',
    resume: 'Tiếp tục',
    restart: 'Chạy lại',
    edit_time_length: 'Chỉnh sửa thời gian',
    set_s_e_time: 'Cài đặt Bắt đầu/Kết thúc',
    edit_budget: 'Chỉnh sửa ngân sách',
    set_budget: 'Cài đặt ngân sách',
    selected: 'Đã chọn',
    all_category: 'Tất cả danh mục',
    shop_category: 'Danh mục Shop',
    image: 'Ảnh',
    set_anti_click: 'Cài đặt cấu hình Chống click ảo',
    set_auto_bid_price: 'Cài đặt điều chỉnh giá thầu tự động',
    set_auto_position: 'Cài đặt duy trì vị trí tự động',
    show_selected_metrics: 'Hiển thị theo chỉ số được chọn',
    select_ads_type: 'Chọn loại quảng cáo',
    select_budget_type: 'Chọn loại ngân sách',
    i_r_by_amount: 'Tăng / giảm theo số tiền',
    i_r_by_percent: 'Tăng / giảm theo phần trăm',
    i_r_budget_by_amount: 'Tăng / giảm ngân sách theo giá tiền',
    i_r_budget_by_percent: 'Tăng / giảm ngân sách theo phần trăm',
    increase: 'Tăng',
    decrease: 'Giảm',
    use_cpc_note: 'Atosa sẽ sử dụng giá thầu gợi ý nếu giá trị CPC bằng 0.',
    use_recommend_price: 'Sử dụng giá thầu gợi ý',
    use_cpc: 'Sử dụng CPC',
    bid_price: 'Giá đấu thầu',
    update_bid_price: 'Điều chỉnh giá đấu thầu', // dieu chinh gia dau thau
    select_cpc_test_time: 'Chọn thời gian kiểm tra CPC',
    last_week: 'Tuần trước',
    last_month: 'Tháng trước',
    last_year: 'Năm trước',
    amount_higher_than_recommend_price: 'Cao hơn số tiền so với giá thầu gợi ý hoặc giá trị CPC',
    amount_less_than_recommend_price: 'Thấp hơn số tiền so với giá thầu gợi ý hoặc giá trị CPC',
    percent_higher_than_recommend_price: 'Cao hơn {percent}% so với giá thầu gợi ý hoặc giá trị CPC',
    percent_less_than_recommend_price: 'Thấp hơn {percent}% so với giá thầu gợi ý hoặc giá trị CPC',
    dont_exceed_the_max: 'Không vượt quá tối đa',
    set_price_by_shopee: 'Thiết lập giá thầu theo giá gợi ý của Shopee hoặc giá trị CPC',
    action_time: 'Thời gian chạy',
    action_time_list: 'Danh sách thời gian chạy',
    select_all_day: 'Chọn cả ngày',
    setting_not_in_action_time: 'Điều chỉnh khi thời gian không nằm trong Khung giờ cài đặt',
    do_nothing: 'Duy trì giá thầu', // Duy tri gia thau
    pause_keyword: 'Tạm dừng từ khóa',
    bid_price_by_amount: 'Cài đặt giá đấu thầu theo số tiền',
    input_amount: 'Nhập số tiền',
    input_percent: 'Nhập phần trăm',
    set_amount: 'Giá thầu cài đặt', // Gia thau cai dat
    position: 'Vị trí',
    input_position: 'Nhập vị trí',
    setup_position: 'Duy trì vị trí trong khoảng', // Duy trì vị trí trong khoảng
    start_position: 'Vị trí bắt đầu',
    end_position: 'Vị trí kết thúc',
    input_start_position: 'Nhập vị trí bắt đầu',
    input_end_position: 'Nhập vị trí kết thúc',
    always_above_position: 'Luôn duy trì ở phía trên vị trí',
    always_below_position: 'Luôn duy trì ở phía duoi vị trí',
    other_setting: 'Cài đặt khác',
    select_device: 'Chọn thiết bị',
    test_device: 'Thiết bị kiểm tra',
    select_test_device: 'Chọn thiết bị kiểm tra',
    extra_amount: 'Số tiền cộng thêm để tăng vị trí',
    minus_amount: 'Số tiền trừ đi để giảm vị trí',
    maximum_amount: 'Giá thầu tối đa',
    minimum_amount: 'Giá thầu tối thiểu',
    auto_position_note: 'Lưu ý: Điều chỉnh vị trí tự động sẽ chạy hiệu quả hơn đối với Từ khóa chính xác.',
    advanced_setting: 'Cài đặt nâng cao',
    optional: 'Tùy chọn',
    active_day: 'Ngày hoạt động',
    active_day_list: 'Danh sách ngày hoạt động',
    fs_day: 'Ngày Flash Sale',
    before_fs_day: 'Trước ngày Flash Sale',
    after_fs_day: 'Sau ngày Flash Sale',
    input_day: 'Nhập ngày',
    starting_amount: 'Giá thầu khởi điểm',
    until: 'cho tới',
    days: 'Số ngày',
    of_every_month: 'hàng tháng',
    batch_bid_price_update: 'Cập nhật giá thầu hàng loạt',
    by_amount: 'Theo số tiền',
    compare_data: 'Đối chiếu dữ liệu',
    time_range: 'Khung thời gian',
    current_time_range: 'Khung thời gian hiện tại',
    select_compare_time: 'Chọn thời gian đối chiếu',
    disable_compare: 'Tắt đối chiếu',
    past_1_week: 'Tuần vừa qua',
    past_1_month: 'Tháng vừa qua',
    product_impression: 'Số lượt xem SP',
    product_clicks: 'Số lượt click SP',
    product_click_rate: 'Tỉ lệ click SP',
    amount_gte_0: 'Số tiền phải lớn hơn 0',
    percent_gte_0: 'Phần trăm phải lớn hơn 0',
    input_max_bid_price: 'Nhập giá thầu tối đa',
    select_active_day: 'Chọn ngày hoạt động',
    input_extra_amount: 'Nhập số tiền cộng thêm',
    input_starting_amount: 'Nhập số tiền khởi điểm',
    start_from: 'Bắt đầu từ',
    the_next_day: 'Ngày hôm sau', // ngay hom sau
    select_action_time: 'Chọn ngày hoạt động',
    input_suggest_percent: 'Nhập phần trăm gợi ý',
    input_enough: 'Nhập đầy đủ dữ liệu',
    fill_input: 'Nhập đầy đủ dữ liệu',
    auto_bid_price_note: 'Bạn chỉ có thể sử dụng 1 trong 2 cấu hình tự động. Nếu bạn chọn cấu hình đấu thầu tự động thì cấu hình vị trí sẽ bị xóa. Bạn có muốn tiếp tục?', // Bạn chỉ có thể sử dụng 1 trong 2 cấu hình tự động. Nếu bạn chọn cấu hình đấu thầu tự động thì cấu hình vị trí sẽ bị xóa. Bạn có muốn tiếp tục?
    auto_position_note2: 'Bạn chỉ có thể sử dụng 1 trong 2 cấu hình tự động. Nếu bạn chọn cấu hình vị trí tự động thì cấu hình đấu thầu tự động sẽ bị xóa. Bạn có muốn tiếp tục?', // `Bạn chỉ có thể sử dụng 1 trong 2 cấu hình tự động. Nếu bạn chọn cấu hình vị trí tự động thì cấu hình đấu thầu tự động sẽ bị xóa. Bạn có muốn tiếp tục?
    export_data_error: 'Lỗi, vui lòng đợi trong ít phút trước khi tiếp tục tải dữ liệu',
    export_data_error_2_1: 'Đã có lỗi khi tải báo cáo - Bước 2.1', // Đã có lỗi khi tải báo cáo - Bước 2.1
    export_data_error_2_2: 'Đã có lỗi khi tải báo cáo - Bước 2.1', // Đã có lỗi khi tải báo cáo - Bước 2.1
    low_balance_msg: 'Số dư tài khoản quảng cáo thấp. Vui lòng nạp thêm tiền để đảm bảo các chương trình quảng cáo không bị gián đoạn', // Số dư tài khoản quảng cáo thấp. Vui lòng nạp thêm tiền để đảm bảo các chương trình quảng cáo không bị gián đoạn.
    finished_loading_data: 'Đã tải xong dữ liệu',
    shopee_update_msg: 'Hệ thống Shopee đang trong quá trình cập nhật, điều này sẽ gây ra lỗi đối với Atosa. Quý khách xin vui lòng đợi trong ít phút.', // Hệ thống Shopee đang trong quá trình cập nhật, điều này sẽ gây ra lỗi đối với Atosa. Quý khách xin vui lòng đợi trong ít phút
    shop_ads_error: 'Hiện tại Shop không đủ điều kiện để sử dụng Quảng cáo Tìm Kiếm Shop', // Hiện tại Shop không đủ điều kiện để sử dụng Quảng cáo Tìm Kiếm Shop
    invalid_start_time: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu', // 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
    budget_needs_more: 'Ngân sách quảng cáo cần lớn hơn ',
    create_ads_confirm: 'Bạn có chắc muốn tạo quảng cáo này không?',
    select_keyword: 'Chọn từ khóa',
    create_ads_success: 'Tạo quảng cáo mới thành công',
    delete_success: 'Xóa thành công',
    time: 'Thời gian',
    config_auto: 'Cấu hình tự động',
    bulk_set_anti_click: 'Cài đặt chống click ảo hàng loạt', // Cài đặt chống click ảo hàng loạt
    bulk_set_auto_cir: 'Tối ưu CIR tự động hàng loạt', // Tối ưu CIR tự động hàng loạt
    delete_configuration: 'Xóa cài đặt tự động', // Xóa cài đặt tự động
    bulk_delete_configuration: 'Xóa cài đặt tự động hàng loạt', // Xóa cài đặt tự động
    delete_anti_click: 'Xóa cấu hình chống Click ảo',
    delete_auto_cir: 'Xóa cấu hình CIR Tự động',
    change_keyword: 'Thay đổi từ khóa',
    change_keyword_type: 'Thay đổi loại từ khóa',
    change_keyword_status: 'Thay đổi trạng thái từ khóa',
    exact_keyword: 'Từ khóa chính xác',
    broad_keyword: 'Từ khóa mở rộng',
    auto_bid_price_running: 'Đang chạy Điều chỉnh giá thầu tự động',
    auto_position_running: 'Đang chạy duy trì vị trí tự động',
    anti_click_running: 'Đang chạy chống Click ảo',
    auto_cir_running: 'Đang chạy Tối ưu CIR Tự động',
    not_set_auto: 'Chưa cài tự động',
    not_set_anti_click: 'Chưa cài chống Click ảo',
    export_keyword_file: 'Tải file từ khóa',
    export_selected_keyword_file: 'Tải file từ khóa đã chọn',
    export_shopee_data: 'Tải báo cáo Shopee', // Tải báo cáo Shopee
    select_n_keyword_on_page: 'Chọn {number} từ khóa trên trang hiện tại', // Chọn {{ keyword_filter.page_size }} từ khóa trên trang hiện tại
    auto_selected: 'Quảng cáo tự động', // Quảng cáo tự động
    restore: 'Khôi phục', // Khôi phục,
    per_click: 'Giá thầu', // Gia thau,
    pin: 'Cố định',
    unpin: 'Bỏ cố định',
    batch_position_check: 'Kiểm tra vị trí hàng loạt', // Kiem tra vi tri hang loat
    setting: 'Cài đặt', // Cai dat
    setup_automation: 'Cài đặt tự động', // cai dat tu dong
    installed: 'Đã cài',
    not_installed: 'Chưa cài',
    setup_anti_click: 'Cấu hình chống Click Ảo',
    setup_auto_cir: 'Cấu hình tối ưu CIR Tự động',
    check_position: 'Kiểm tra', // Kiem tra
    average_ranking: 'Thứ hạng TB', // Thu hang TB
    suggested: 'Giá gợi ý', // Gia goi y
    shopee_suggested: 'Shopee đề xuất', // Shopee de xuat
    atosa_keyword_tool: 'Công cụ từ khóa Atosa',
    search_keyword: 'Tìm kiếm từ khóa',
    select_keyword_file: 'Chọn file từ khóa',
    select_file: 'Chọn file', // Chon file
    import_file_note: 'Hãy lưu từ khóa ở cột đầu tiên, giá thầu ở cột thứ hai (Giá thầu không bắt buộc)', // Hãy lưu từ khóa ở cột đầu tiên, giá thầu ở cột thứ hai (Giá thầu không bắt buộc)
    add_keywords: 'Từ khóa đã thêm',
    add_all_keyword: 'Thêm tất cả từ khóa',
    add_top_keyword: 'Thêm {number} từ khóa đầu tiên',
    remove_duplicated_keyword: 'Loại bỏ các từ khóa bị trùng', // Loại bỏ các từ khóa bị trùng
    confirm_and_add_keywords: 'Xác nhận và thêm từ khóa', // Xác nhận và thêm từ khóa
    bulk_bid_price_update: 'Điều chỉnh giá thầu đồng loạt', // Điều chỉnh giá thầu đồng loạt
    i_r_by_shopee_amount: 'Tăng / giảm giá thầu theo số tiền của Giá gợi ý hoặc CPC', // Tăng / giảm giá thầu theo số tiền của Giá gợi ý hoặc CPC
    i_r_by_shopee_percent: 'Tăng / giảm giá thầu theo % của Giá gợi ý hoặc CPC', // Tăng / giảm giá thầu theo % của Giá gợi ý hoặc CPC
    by_recommend_price: 'Theo Giá thầu Shopee gợi ý hoặc CPC',
    update_keyword: 'Cập nhật từ khóa',
    update_keyword_type: 'Cập nhật loạt từ khóa',
    update_keyword_status: 'Cập nhật trạng thái từ khóa',
    bulk_keyword_update: 'Cập nhật từ khóa hàng loạt',
    bulk_keyword_type_update: 'Cập nhật loạt từ khóa hàng loạt',
    bulk_keyword_status_update: 'Cập nhật trạng thái từ khóa hàng loạt',
    running: 'Running',
    convert_table: 'Bảng quy đổi vị trí giữa Web và App Shopee', // Bảng quy đổi vị trí giữa Web và App Shopee
    web_position: 'Vị trí Web',
    mobile_position: 'Vị trí Mobile',
    added_keywords: 'Từ khóa đã thêm',
    rate_of: 'Tỉ lệ %',
    minimum_clicks: 'Số lượt click tối thiểu', // Số lượt click tối thiểu
    maximum_clicks: 'Số lượt click tối đa', // Số lượt click tối đa
    cir_rate: 'Tỉ lệ % CIR', // Tỉ lệ % CIR
    maximum_cost: 'Chi phí tối đa',
    input_maximum_cost: 'Nhập chi phí tối đa',
    not_required: 'Không bắt buộc',
    select_test_time: 'Chọn thời gian kiểm tra',
    check_if_no_order: 'Kiểm tra khi không có đơn hàng', // Kiểm tra khi không có đơn hàng
    gmv_is_0: 'GMV bằng 0', // (GMV bằng 0)
    maximum_cost_no_order: 'Chi phí tối đa khi không có đơn hàng', // Chi phí tối đa khi không có đơn hàng
    anti_click_action: 'Hành động khi phát hiện click ảo', // Hành động khi phát hiện click ảo
    broad_cir: 'CIR mở rộng',
    cir_gte_0: 'CIR phải lớn hơn 0',
    minimum_clicks_gte_0: 'Số click tối thiểu phải lớn hơn 0',
    maximum_clicks_gte_0: 'Số click tối đa phải lớn hơn 0',
    rate_value_gte_0: 'Tỉ lệ % phải lớn hơn 0',
    input_maximum_cost_no_order: 'Nhập giá thầu tối đa khi không có đơn hàng',
    delete_selected_items_confirm: 'Bạn có muốn xóa những mục đã chọn không?',
    input_cir: 'Nhập giá trị CIR',
    select_cir_type: 'Chọn kiểu CIR',
    select_1_option: 'Bạn phải chọn ít nhất 1 mục',
    save_confirm: 'Bạn có muốn lưu dữ liệu này không?',
    input_bid_price: 'Nhập giá thầu', //
    cant_delete_all: 'Không thể xóa toàn bộ từ khóa, bạn phải để lại ít nhất 1 từ',
    delete_keyword_confirm: 'Bạn có muốn xóa từ khóa này không "{keyword}"?',
    delete_keywords_confirm: 'Bạn có muốn xóa những từ khóa này không?',
    delete_config_confirm: 'Bạn có muốn xóa cấu hình này không?',
    delete_configs_confirm: 'Bạn có muốn xóa những cấu hình này không?',
    add_1_keyword: 'Vui lòng thêm ít nhất 1 từ khóa', // Vui lòng chọn ít nhất 1 từ khóa để thêm
    keyword_exceeds: 'Số lượng từ khóa vượt quá {n1} words. Số từ khóa hiện tại {n2}',
    bid_price_gte_0: 'Giá thầu phải lớn hơn 0',
    contain_broad_keyword: 'Lưu ý: Trong danh sách cấu hình có từ khóa mở rộng. Bạn có muốn tiếp tục?', // Lưu ý: Trong danh sách cấu hình có từ khóa mở rộng. Bạn có muốn tiếp tục
    no_keyword_to_export: 'Không có từ khóa để tải về',
    loading_data_error: 'Đã có lỗi xảy ra khi tải dữ liệu!',
    saved_bid_price: 'Giá thầu đã lưu',
    use_saved_bid_price: 'Sử dụng giá thầu đã lưu',
    allow_delete_old_keywords: 'Cho phép xóa tất cả từ khóa cũ',
    confirm_and_create_new_ads: 'Xác nhận và tạo quảng cáo mới',
    cant_delete_this_product: 'Không thể xóa sản phẩm này',
    ads_name: 'Tên quảng cáo', // Ten QC
    input_ads_name: 'Nhập tên quảng cáo',
    auto_maintain_display_running: 'Duy trì hiển thị tự động đang chạy',
    update_campaign: 'Cập nhật Chiến dịch',
    tag_line: 'Khẩu hiệu quảng cáo (Tagline)', // Khẩu hiệu quảng cáo (Tagline)
    input_tag_line: 'Nhập nhập khẩu hiệu quảng cáo',
    tag_line_not_contain: 'Khẩu hiệu quảng cáo của bạn không thể chứa ', // Khẩu hiệu quảng cáo của bạn không thể chứa
    link_shop: 'Trang liên kết', // Trang lien ket
    home_page: 'Trang chủ Shop (Mặc định)', // Trang chủ Shop (Mặc định)
    category_page: 'Trang danh mục Shop', // Trang danh mục Shop
    select_category: 'Chọn danh danh mục',
    error_code: 'Mã lỗi: ',
    ads_name_in_used: 'Tên chiến dịch đã được sử dụng', // Tên chiến dịch đã được sử dụng
    profile: 'Thông tin tài khoản', // Thong tin tai khoan,
    update_profile_msg: 'Vui lòng cập nhật thông tin của bạn để chúng tôi có thể hỗ trợ khi có vấn đề hoặc khi quên mật khẩu', //  Vui lòng cập nhật thông tin của bạn để chúng tôi có thể hỗ trợ khi có vấn đề hoặc khi quên mật khẩu.
    update_profile_confirm: 'Bạn có chắc muốn cập nhật thông tin cá nhân của mình không?', // Bạn có chắc muốn cập nhật thông tin cá nhân của mình không?
    update_password_confirm: 'Cập nhật mật khẩu sẽ phải đăng nhập lại, bạn có chắc vẫn muốn thay đổi chứ?', // Cập nhật mật khẩu sẽ phải đăng nhập lại, bạn có chắc vẫn muốn thay đổi chứ?
    current_password_not_correct: 'Mật khẩu hiện tại không chính xác, vui lòng kiểm tra và thử lại sau!', // Mật khẩu hiện tại không chính xác, vui lòng kiểm tra và thử lại sau!
    confirm_password_not_correct: 'Mật khẩu xác nhận không chính xác',
    invalid_phone_number: 'Số điện thoại không hợp lệ, vui lòng kiểm tra và thử lại sau!',
    invalid_email: 'Địa chỉ email không hợp lệ vui lòng kiểm tra và thử lại sau!',
    invalid_display_name: 'Tên hiển thị không hợp lệ, vui lòng kiểm tra và thử lại sau!',
    input_budget: 'Nhập ngân sách',
    final_bid_price: 'Khoảng giá thầu cuối cùng', // Khoảng giá thầu cuối cùng
    display_locations: 'Vị trí hiển thị', // Vị trí hiển thị
    placement_1: 'Trang chi tiết sản phẩm - Sản phẩm tương tự', // Trang chi tiết sản phẩm - Sản phẩm tương tự
    placement_2: 'Trang chủ - Gợi ý hôm nay', // Trang chủ - Gợi ý hôm nay
    placement_5: 'Trang chi tiết sản phẩm - Có thể bạn cũng thích', // Trang chi tiết sản phẩm - Có thể bạn cũng thích
    auto_optimisation: 'Quảng cáo tự động', // Quang cao tu dong
    auto_premium: 'Điều chỉnh Premium Tự động',
    premium_rate: 'Mức premium',
    update_premium_rate: 'Cập nhật mức Premium',
    bid_price_gte: 'Giá thầu phải lớn hơn {value}',
    input_premium_rate: 'Nhập mức premium',
    premium_rate_gte_0: 'Mức Premium phải lớn hơn 0',
    premium_rate_lte_300: 'Mức Premium phải nhỏ hơn 300',
    setup_new_ads: 'Thiết lập quảng cáo mới',
    setup: 'Thiết lập', // Thiet lap
    permission_error_1: 'Lỗi phân quyền: Không tìm thấy tính năng', // Lỗi phân quyền: Không tìm thấy tính năng
    permission_error_2: 'Lỗi phân quyền: Không tìm thấy hành động', // Lỗi phân quyền: Không tìm thấy hành động
    permission_error_3: 'Bạn không có quyền truy cập tính năng', // Bạn không có quyền truy cập tính năng
    need_apply_package_msg: 'Quý khách cần áp dụng gói để sử dụng tính năng này.', // Quý khách cần áp dụng gói để sử dụng tính năng này.
    related_product_link: 'Link sản phẩm liên quan', // Link sản phẩm liên quan
    your_product: 'Sản phẩm của bạn', //
    search_product_link: 'Đường dẫn sản phẩm tìm kiếm (Sản phẩm của đối thủ)', // Đường dẫn sản phẩm tìm kiếm (Sản phẩm của đối thủ)
    add_product: 'Thêm sản phẩm',
    search_type: 'Kiểu tìm kiếm',
    daily_discover: 'Gợi ý hôm nay', // Gợi ý hôm nay
    found_position: 'Vị trí tìm thấy',
    check_position_discovery_ads: 'Kiểm tra vị trí Quảng cáo khám phá',
    related_product: 'Sản phẩm liên quan',
    you_may_also_like: 'Có thể bạn cũng thích',
    product_not_found: 'Không tìm thấy sản phẩm',
    learn_more_atosa: 'Tìm hiểu thêm về Tool hỗ trợ chạy Quảng cáo Shopee an toàn nhất',
    here: 'Tại đây',
    limited_shop: 'Thời hạn bản quyền hết hạn hoặc số cửa hàng đạt giới hạn!',
    waiting_for_otp: 'Đang chờ xác nhận OTP trên Thiết bị di động hoặc hòm thư Email. Thời gian xác nhận là {second} giây.',
    cant_verify_otp: 'Không thể xác minh OTP. Vui lòng báo với quản trị viên.',

    platform: 'Nền tảng',
    advance: 'Nâng cao',
    display: 'Hiển thị',
    pricing: 'Bảng giá',
    about_us: 'Về chúng tôi',
    views: 'Lượt xem',
    likes: 'Lượt thích',
    comments: 'Lượt bình luận',
    shares: 'Lượt chia sẻ',
    last_seen: 'Lần xem cuối',
    released_date: 'Ngày phát hành',
    sort_by: 'Sắp xếp theo',
    day_s: 'ngày',
    week_s: 'tuần',
    month_s: 'tháng',
    country: 'Quốc gia',
    latest_video: 'Video mới nhất',
    results_per_page: '{number} kết quả mỗi trang',
    up_to_results: 'Tối đa {number} kết quả',
    or: 'hoặc',
    enter: 'Nhập',
    download: 'Tải về',
    title: 'Tiêu đề',
    all_features: 'Bao gồm tất cả tính năng',
    enjoin_all: 'Tận hưởng tất cả tính năng',
    results: 'Kết quả',
    choose_plan: 'Mua gói',
    unlimited: 'Không giới hạn',
    unlimited_download: 'Không giới hạn tải video',
    and_many_more: 'và nhiều tiện ích khác',
    our_best_pricing: 'Gói Sản Phẩm',
    invalid_license: 'Bản quyền không hợp lệ',
    query_history: 'Lịch sử truy vấn',
    forbidden: '403 Forbidden Error',
    should_login: 'Đăng Nhập Để Sử Dụng Toàn Bộ Các Tính Năng',
    upgrade_license: 'Nâng Cấp Gói Sản Phẩm Của Bạn Để Có Trải Nghiệm Tốt Hơn',
    info: 'Thông báo',
    video_detail: 'Chi Tiết Video',
    video_location: 'Vị trí',
    author_link: 'Tác giả',
    updated_time: 'Ngày cập nhật',
    renewal: 'Gia hạn',
    require_password: 'Yêu cầu mật khẩu để cập nhật thông tin cá nhân',
    re_login_msg: 'Thay đổi mật khẩu sẽ cần phải đăng nhập lại',
    ads_video: 'Video Quảng cáo',
    download_video: 'Tải video',
    copied: 'Đã sao chép!'
  },
  en: {
    hi: 'Hi',
    active: 'Active',
    balance: 'Balance',
    pack: 'Package',
    package: 'Package',
    lang: 'Language',
    account: 'Account info',
    update: 'Update',
    update_successfully: 'Update successfully',
    login: 'Login',
    register: 'Register',
    register_now: 'Register now',
    send_request: 'Send request',
    exist_account: 'Have an account? Register now',
    invalid_email_format: 'Invalid email format',
    password_length: 'Password must have at least {length} characters',
    password_match: 'Confirm password is not match',
    change_password: 'Change password',
    logout: 'Logout',
    quantity: 'Quantity',
    product: 'Product',
    duration: 'Duration',
    price: 'Price',
    start_time: 'Start time',
    end_time: 'End time',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    year: 'Year',
    hour: 'Hour',
    minute: 'Minute',
    second: 'Second',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    close: 'Close',
    attribute: 'Attribute',
    value: 'Value',
    yes: 'Yes',
    no: 'No',
    trial: 'Trial',
    package_name: 'Package name',
    description: 'Description',
    note: 'Note',
    action: 'Action',
    option: 'Option',
    proxy_status: 'Proxy Status',
    created_at: 'Created At',
    default: 'Default',
    delete: 'Delete',
    region: 'Country',
    select: 'Select',
    all: 'All',
    paid: 'Paid',

    warning: 'Warning',
    error: 'Error',
    success: 'Success',
    confirm: 'Confirm',
    cancel: 'Cancel',
    successfully: 'Successfully!',
    system_error: 'System Error!',

    display_name: 'Display Name',
    username: 'Username',
    password: 'Password',
    current_password: 'Current Password',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    forgot_password: 'Forgot password',
    phone_number: 'Phone number',
    email: 'Email address',

    login_title: 'LOGIN ATOSA',
    login_sub_title: 'Shopee software with the most users',
    login_successfully: 'Login successfully',

    register_title: 'REGISTER ATOSA',
    register_sub_title: 'Shopee software with the most users',
    register_note: 'Password will be sent to your email.',
    register_ban_ip: 'Maybe your IP has been blocked, please turn off your ad blocker and try again or contact support.',
    register_confirm: 'Password will be sent to {email}. Did you enter your email correctly?',
    register_successfully: 'Send request successfully, please check your email',
    register_invalid_captcha: 'Invalid Captcha!',
    register_account_exists: 'Email address already exists',

    input_username: 'Enter username',
    input_display_name: 'Enter display name',
    input_password: 'Enter password',
    input_current_password: 'Enter current password',
    input_phone_number: 'Enter phone number',
    input_email: 'Enter email address',
    input_new_password: 'Enter new password',
    input_confirm_password: 'Enter confirm password',
    input_voucher: 'Enter voucher code',
    input_keyword: 'Enter keyword',
    input_otp: 'Enter otp',

    shop_error_perm: 'Account or password is not correct!',
    shop_error_banned: 'Account has been locked, please check and try again!',
    shop_error_require_captcha: 'Captcha code required! Please login successfully on the browser then log back in on Atosa!',
    shop_error_too_frequent: 'Captcha code required! Please login successfully on the browser then log back in on Atosa!',
    shop_error_captcha: 'Captcha is not correct, please check and try again!',
    shop_error_notfound: 'Account or password is not correct, please check and try again!',
    shop_error_need_otp: 'OTP required, please access to Shopee Shop and login again!',
    shop_error_need_ivs: 'Please verify OTP on Shopee app mobile!',
    shop_error_info: 'Can not sign. Please contact us for supporting!',
    shop_otp_required: 'OTP required, please verify otp',

    server_wrong_account: 'Account or password is not correct, please try again!',

    forget_password: 'Forgot password',
    forgot_password_title: 'Password recovery',
    forgot_password_note: 'We will send a change password request to your email',
    forgot_back_to_login: 'Back to login',
    forgot_send_request_successfully: 'Send request successfully',
    forgot_new_password_title: 'SETUP NEW PASSWORD',
    forgot_new_password_greeting: 'Hi {name}, Let\'s setup a new password for {email}',

    payment: 'Payment',
    atosa_package: 'Atosa Package',
    recharge: 'Payment',
    atosa_course: 'Shopee Ads Course',
    management: 'Management',
    shopee_shop: 'Shopee Shops',
    shop_center: 'Shop Center',
    shop_product: 'Shop Products',
    atosa_tool: 'Atosa Tool',
    seo_position: 'Check Seo Position',
    keyword_tool: 'Atosa Keyword Tool',
    shop_analysis: 'Shop Analysis',
    spoil_ads: 'Spoil Ads Position',
    shop_survey: 'Shop Survey',
    keyword_ads: 'Keyword Ads',
    auto_ads: 'Automation Ads',
    anti_click_log: 'Anti Click Report',
    check_ads_position: 'Check Ads Position',
    guideline: 'Guideline',
    customer_support: 'Customer Support',
    anti_click: 'Anti Click',
    shop_connect: 'Shop Connect',
    delete_keyword: 'Delete Keyword',
    delete_keywords: 'Delete keywords',
    using_package: 'Using Package',
    bought_package: 'Bought Package',
    license_to: 'License to',
    apply_now: 'Apply now',
    buy_package: 'Buy package',
    payments: 'Payments',
    online_payment: 'Online payment',
    used_package_has: 'Currently used package has',
    confirm_buy: 'Confirm buy',
    voucher_info: 'Discount code / Voucher',
    discount_for_bill: 'Discount {value}% for total order value',
    discount_for_package: 'Apply for package',
    discount_minimize: 'Minimize',
    discount_minimum_order: 'Minimum order',
    total_order: 'Total order',
    see_feature: 'See feature',
    using: 'USING',
    see_detail: 'See detail',
    package_is_expr: 'Your Shopee Marketing package is expired. Please buy package to continue using.',
    confirm_apply_package_1: 'Do you want to apply package: {package} [Channel {channel}]?',
    confirm_apply_package_2: 'Do you want to apply package: {package} [Channel {channel}]? The package you are using will be added 1 month.',
    confirm_apply_package_3: 'Do you want to apply package: {package} [Channel {channel}]? If you apply this package, your current package will be lost.',
    confirm_buy_package: 'Do you want to buy package: {package} x {month} month(s) for {total_order} VND?',
    balance_not_enough: 'Current balance ({balance} VND) is not enough to make a transaction, do you want to recharge now?',
    apply_for_1_month: 'Apply for 1 month',
    dub_click_for_detail: 'Double click to see detail',
    invalid_voucher: 'Invalid voucher',

    shop_list: 'Shop list',
    add_shop: 'Add shop',
    shop_note_1: 'Please contact us to change shop\'s username.',
    shop_note_2: 'Choose a default shop to automatically select shop when using Atosa.',
    shop_note_3: 'Atosa has supported to enable shop\'s OTP (Via SMS or Shopee App).',
    shop_note_4: 'When you enable OTP, please login again on Atosa.',
    re_login: 'Re-login',
    config_proxy: 'Config proxy',
    test_proxy: 'Test proxy',
    delete_proxy: 'Delete proxy',
    delete_shop: 'Delete shop',
    account_link: 'Account link',
    account_linked: 'Account linked',
    re_link_account: 'Click to re-link the account',
    using_system_proxy: 'Using system proxy',
    save_config: 'Save config',
    save_config_successfully: 'Save config successfully',
    default_selected_shop: 'Default shop selected',
    confirm_delete_shop: 'Do you want to delete this shop? All configurations will be deleted.',
    please_verify_otp: 'Please verify OTP on your mobile device!',
    re_login_error: 'An error occurred when re-login',
    invalid_region: 'Invalid country',
    proxy_select: 'Please select proxy type',
    proxy_server: 'Please enter proxy server',
    proxy_port: 'Please enter proxy port',
    proxy_connected: 'Proxy connect successfully',
    invalid_proxy: 'Invalid proxy',
    delete_proxy_confirm: 'Do you want delete this proxy?',
    delete_proxy_successfully: 'Delete proxy successfully',

    select_shop: 'Select Shop',
    added_shop: 'Added Shops',
    sub_shop: 'Sub Shops',
    select_time: 'Select Time',
    select_start_time: 'Select Start Time',
    select_end_time: 'Select End Time',
    business_insight: 'Business Insights',
    date_period: 'Date Period',
    real_time: 'Real time',
    today_period: 'Today Until {hour}:00',
    vs_today_period: 'vs 00:00-{hour}:00 Yesterday',
    today: 'Today',
    yesterday: 'Yesterday',
    vs_yesterday: 'vs yesterday',
    past_days: 'Past {day} days',
    vs_past_days: 'vs Previous {day} days',
    by_day: 'By day',
    vs_by_day: 'vs Previous day',
    by_week: 'By week',
    vs_by_week: 'vs Previous week',
    by_month: 'By month',
    vs_by_month: 'vs Previous month',
    by_year: 'By year',
    vs_by_year: 'vs Previous year',
    refresh_data: 'Refresh Data',
    reload_data: 'Reload Data',
    loading_data: 'Loading Data',
    all_ads: 'All Ads',
    search_ads: 'Search Ads',
    discovery_ads: 'Discovery Ads',
    select_metrics: 'Select metrics',
    metrics: 'Metrics',

    impression: 'Impression',
    clicks: 'Clicks',
    ctr: 'CTR',
    expense: 'Expense',
    conversions: 'Conversions',
    items_sold: 'Items Sold',
    conversion_rate: 'Conversion Rate',
    cost_per_conversion: 'Cost per Conversion',
    direct_conversions: 'Direct Conversions',
    direct_items_sold: 'Direct Items Sold',
    cpc: 'CPC',
    gmv: 'GMV',
    direct_gmv: 'Direct GMV',
    roi: 'ROI',
    direct_roi: 'Direct ROI',
    cir: 'CIR',
    direct_cir: 'Direct CIR',
    direct_conversion_rate: 'Direct Conversion Rate',
    cost_per_direct_conversion: 'Cost per Direct Conversion',
    budget: 'Budget',
    shop_metrics: 'Shop Metrics',
    use_ads_expense: 'Use ads expense',
    all_order: 'All Order',
    placed_order: 'Placed Order',
    paid_order: 'Paid Order',
    export_data: 'Export Data',
    download_data: 'Download Data',
    sales: 'Sales',
    orders: 'Orders',
    visitors: 'Visitor',
    page_views: 'Page views',
    sales_per_order: 'Sales per Order',
    ads_metrics: 'Ads Metrics',
    minimize: 'Minimize',
    go_search_ads: 'Go Search Ads',
    go_discovery_ads: 'Go Discovery Ads',
    chart: 'Chart',
    order_metrics: 'Order Metrics',
    buyer_info: 'Buyer Information',
    total_buyers: 'Total Buyers',
    new_buyers: 'New Buyers',
    existing_buyers: 'Existing Buyers',
    followers: 'Followers',
    return_rate: 'Return Rate',
    add_to_cart: 'Add to Cart',
    select_top_product: 'Select top {number} products',
    customer_segment: 'Customer Segment',
    by_price_segment: 'By Price Segment',
    buyer: 'Buyer',
    buyers: 'Buyers',
    uncheck: 'Uncheck',
    checked: 'Checked',
    check: 'Check',
    create_new_ads: 'Create New Ads',
    connected_seller: 'Connected seller',
    connected_seller_fail: 'An error occurred when connecting seller',
    load_success: 'Load data successfully',
    selected_shop: 'Default shop selected',

    product_list: 'Product list',
    please_select_product: 'Please select a product',
    please_select_time: 'Please select time',
    an_error: 'An error has occurred',
    chart_large_data: 'Can\'t show all chart because data is too large. The feature is being updated.',

    stock: 'Stock',
    product_note: 'Note: Use the preview feature to check product name before renaming!',
    add_text: 'Add Text',
    remove_text: 'Remove Text',
    preview_product: 'Preview Product',
    old_name: 'Old Name',
    new_name: 'New Name',
    input_add_text: 'Enter add text',
    input_remove_text: 'Enter remove text',
    add_position: 'Add Position',
    in_front_name: 'In front of the product name',
    behind_name: 'Behind of the product name',
    skip_preview: 'Skip preview',
    preview: 'Preview',
    other_option: 'Other Options',
    case_sensitive: 'Case Sensitive',
    delete_only_once: 'Delete Only Once',
    update_name_confirm: 'Do you want update product name?',

    check_seo_position: 'Check SEO Position (Search)',
    check_seo_position_similar: 'Check SEO Position (Similar Product)',
    product_link: 'Product Link',
    input_product_link: 'Enter product link',
    select_region: 'Select Country',
    max_position: 'Max position',
    keyword: 'Keyword',
    keywords: 'Keywords',
    add_keyword: 'Add Keyword',
    remove_keyword: 'Remove keyword',
    order_by: 'Order By',
    relevancy: 'Relevancy',
    newest: 'Newest',
    top_sales: 'Top Sales',
    device: 'Device',
    search: 'Search',
    lookup: 'Lookup',
    click_to_stop: 'Click to Stop',
    stop: 'Stop',
    searching_position: 'Searching Position',
    display_position: 'Display Position',
    remove: 'Remove',
    status: 'Status',
    waiting: 'Waiting',
    searching: 'Searching',
    complete: 'Complete',
    completed: 'Completed',
    invalid_product_link: 'Invalid Product Link',
    invalid_shop_link: 'Invalid Shop Link',
    ads_position: 'Ads Position',

    keyword_file: 'Keyword file',
    saved_keyword: 'Saved keyword',
    saved_keyword_file: 'Saved Keyword File',
    keyword_analysis: 'Analyze',
    select_top_keyword: 'Select {number} top keywords',
    uncheck_all: 'Uncheck All',
    delete_selected_keyword: 'Deleted Selected Keyword',
    add_selected_keyword: 'Add Selected Keyword',
    show_current_keyword: 'Show Current Keyword',
    show_keyword_tool: 'Show Keyword Tool',
    filter: 'Filter',
    delete_filter: 'Delete Filter',
    compare: 'Compare',
    volume: 'Volume',
    volumes: 'Volumes',
    shopee_volume: 'Shopee Volume',
    shopee_volumes: 'Shopee Volumes',
    recommend_price: 'Recommend Price',
    delete_all_keyword: 'Delete All Keyword',
    import_excel: 'Import Excel',
    your_price: 'Your Price',
    no_keyword: 'you don\'t have any keywords yet, add your keyword',
    filename: 'Filename',
    input_filename: 'Enter filename',
    save_file: 'Save file',
    not_shop: 'You haven\'t connected any account yet, please select a shop and retry!',
    add: 'Add',
    added: 'Added',
    selected_keyword: 'Selected Keyword',
    selected_keywords: 'Selected Keywords',
    total_volumes: 'Total Volumes',
    total_shopee_volumes: 'Total Shopee Volumes',
    please_select_shop: 'Please select shop',
    not_for_trial: 'This feature does not apply to the Trial Package',
    at: 'At',
    save_keyword_file: 'Save keyword file',
    save_keyword_file_confirm: 'Do you want to save this file as {name}?',
    chart_showing_msg: 'Chart showing search volume of keywords on devices',
    getting_shopee_volumes: 'Getting Shopee Volumes',
    updated_shopee_volumes: 'Updated Shopee Volumes And Recommend Price',
    cannot_connect_fff: 'Can not connect to the account, please try later!',
    column: 'Column',
    column_name: 'Column Name',
    expression: 'Expression',
    relevance: 'Relevance',
    select_product: 'Select Product',
    execute: 'Execute',
    cost: 'Cost',
    keyword_no_result: 'No results found for keyword: ',
    total: 'Total',
    no_product: 'Your shop has no product',
    keyword_file_list: 'Keyword File List',
    created_by: 'Created By',
    no_data: 'No data',
    no_data_yet: 'No data yet',
    detail: 'Detail',
    save_price: 'Save Price',
    save_volumes: 'Save Volumes',
    current_price: 'Current Price',
    current_volumes: 'Current Volumes',
    update_confirm: 'Do you want update this?',
    update_file_confirm: 'Do you want update this file?',
    loading_any_data_confirm: 'Loading data, please wait...',
    delete_any_data_confirm: 'Do you want delete this?',
    delete_file_confirm: 'Do you want delete this file?',
    keyword_list: 'Keyword List',
    add_to_current_keyword: 'Add to [Current Keyword]',
    keyword_file_updated: 'Keyword File Updated',
    keyword_file_deleted: 'Keyword File Deleted',

    shop_analysis_note: 'Data analysis is based on the displayed data on the Shopee Web Page.',
    analyze: 'Analyze',
    total_sold: 'Total Sold',
    month_sold: 'Sold (Month)',
    month_sales: 'Sales (Month)',
    month_sales_per_order: 'Sales per Order (Month)',
    total_views: 'Total Views',
    product_name: 'Product Name',
    discount: 'Discount',
    created_time: 'Created Time',
    day_number: 'Day Number',
    auto_config: 'Auto Configuration',
    auto_config_list: 'Auto Configuration List',
    shop_link: 'Shop Link',
    input_shop_link: 'Enter shop link',
    saved_file_list: 'Saved Files',
    manual_save: 'Manual Save',
    auto_save: 'Auto Save',
    shop_name: 'Shop Name',
    save_type: 'Save type',
    file_detail: 'File Detail',

    spoil_ads_position: 'Spoil Ads Position',
    select_top_note: 'Note: Atosa will only show top {number}',
    ads_product_list: 'Ads Products List',
    total_reviews: 'Total Reviews',
    reviews_orders: 'Reviews / Orders',
    ads_type: 'Ads Type',
    auto: 'Automatic',
    manual: 'Manual',
    keyword_type: 'Keyword Type',
    broad: 'Broad',
    exact: 'Exact',

    price_range: 'Price Range',
    from: 'From',
    to: 'To',
    max_number: 'Max number',
    input_max_number: 'Enter max number',
    category: 'Category',
    category_level: 'Category Level {number}',
    products: 'Products',
    sold: 'Sold',

    ongoing: 'Ongoing',
    paused: 'Paused',
    ended: 'Ended',
    ads_credit: 'Ads Credit',
    guide_for_search_ads: 'Guide For Search Ads',
    guide_for_discovery_ads: 'Guide For Discovery Ads',
    bulk_budget_update: 'Bulk Budget Update',
    batch_auto_install: 'Batch Automatic Installation',
    update_max_price: 'Update Max Price',
    loading_campaign_list: 'Loading Campaign List',
    campaign_list: 'Campaign List',
    search_ads_detail: 'Search Ads Detail',
    discovery_ads_detail: 'Discovery Ads Detail',
    shop_ads_detail: 'Shop Ads Detail',
    back: 'Back',
    deleted: 'Deleted',
    soldout: 'Soldout',
    no_limit: 'No Limit',
    no_time_limit: 'No Time Limit',
    type: 'Type',
    daily_budget: 'Daily Budget',
    total_budget: 'Total Budget',
    pause: 'Pause',
    resume: 'Resume',
    restart: 'Restart',
    edit_time_length: 'Edit Time Length',
    set_s_e_time: 'Set Start/End Time',
    edit_budget: 'Edit Budget',
    set_budget: 'Set Budget',
    selected: 'Selected',
    all_category: 'All Category',
    shop_category: 'Shop Category',
    image: 'Img',
    set_anti_click: 'Set Anti Click Configuration',
    set_auto_bid_price: 'Set Auto Bid Price Configuration',
    set_auto_position: 'Set Auto Position Configuration',
    show_selected_metrics: 'Show by Selected Metrics',
    select_ads_type: 'Select Ads Type',
    select_budget_type: 'Select Budget Type',
    i_r_by_amount: 'Increase/decrease by Amount',
    i_r_by_percent: 'Increase/decrease by Percent',
    i_r_budget_by_amount: 'Increase/decrease Budget by Amount',
    i_r_budget_by_percent: 'Increase/decrease Budget by Percent',
    increase: 'Increase',
    decrease: 'Decrease',
    use_cpc_note: 'Atosa will use Recommend Price if the CPC value is 0.',
    use_recommend_price: 'Use Recommend Price',
    use_cpc: 'Use CPC',
    bid_price: 'Bid Price',
    update_bid_price: 'Update Bid Price', // dieu chinh gia dau thau
    select_cpc_test_time: 'Select CPC Test Time',
    last_week: 'Last Week',
    last_month: 'Last Month',
    last_year: 'Last Year',
    amount_higher_than_recommend_price: 'Amount Higher than Recommend Price or CPC', // Cao hơn số tiền so với giá thầu gợi ý hoặc giá trị CPC,
    amount_less_than_recommend_price: 'Amount Less than Recommend Price or CPC', // Thấp hơn số tiền so với giá thầu gợi ý hoặc giá trị CPC,
    percent_higher_than_recommend_price: '{percent}% Higher than Recommend Price or CPC', // Cao hơn số tiền so với giá thầu gợi ý hoặc giá trị CPC,
    percent_less_than_recommend_price: '{percent}% Less than Recommend Price or CPC', // Thấp hơn số tiền so với giá thầu gợi ý hoặc giá trị CPC,
    dont_exceed_the_max: 'Do not exceed the maximum',
    set_price_by_shopee: 'Set by Recommend Price or CPC', // Thiết lập giá thầu theo giá gợi ý của Shopee hoặc giá trị CPC
    action_time: 'Action Time',
    action_time_list: 'Action Time List',
    select_all_day: 'Select All Day',
    setting_not_in_action_time: 'Setting when the time is not in Aciton Time', // Điều chỉnh khi thời gian không nằm trong Khung giờ cài đặt
    do_nothing: 'Do Nothing', // Duy tri gia thau
    pause_keyword: 'Pause Keyword',
    bid_price_by_amount: 'Bid Price by Amount',
    input_amount: 'Enter Amount',
    input_percent: 'Enter percent',
    set_amount: 'Set Amount', // Gia thau cai dat
    position: 'Position',
    input_position: 'Enter Position',
    setup_position: 'Setup Position', // Duy trì vị trí trong khoảng
    start_position: 'Start Position',
    end_position: 'End Position',
    input_start_position: 'Enter Start Position',
    input_end_position: 'Enter End Position',
    always_above_position: 'Always above position', // Luôn duy trì ở phía trên vị trí
    always_below_position: 'Always below position', // Luôn duy trì ở phía duoi vị trí
    other_setting: 'Other Setting',
    select_device: 'Select Device',
    test_device: 'Testing Device',
    select_test_device: 'Select Testing Device',
    extra_amount: 'Extra Amount to Increase Position',
    minus_amount: 'Minus Amount to Decrease Position',
    maximum_amount: 'Maximum Bid Price',
    minimum_amount: 'Minimum Bid Price',
    auto_position_note: 'Note: Auto Position works better for Extract Keyword.', // Lưu ý: Điều chỉnh vị trí tự động sẽ chạy hiệu quả hơn đối với Từ khóa chính xác.
    advanced_setting: 'Advanced Setting',
    optional: 'Optional',
    active_day: 'Active Day',
    active_day_list: 'Active Day List',
    fs_day: 'Flash Sale Day',
    before_fs_day: 'Before Flash Sale Day',
    after_fs_day: 'After Flash Sale Day',
    input_day: 'Enter day',
    starting_amount: 'Starting Amount',
    until: 'Until',
    days: 'Days',
    of_every_month: 'Of Every Month',
    batch_bid_price_update: 'Batch Bid Price Update',
    by_amount: 'By Amount',
    compare_data: 'Compare Data',
    time_range: 'Time Range',
    current_time_range: 'Current Time Range',
    select_compare_time: 'Select Compare Time',
    disable_compare: 'Disable Compare',
    past_1_week: 'Past 1 week',
    past_1_month: 'Past 1 month',
    product_impression: 'Product Impression',
    product_clicks: 'Product Clicks',
    product_click_rate: 'Product Clicks Rate',
    amount_gte_0: 'Amount must be greater than 0',
    percent_gte_0: 'Percent must be greater than 0',
    input_max_bid_price: 'Enter maximum bid price',
    select_active_day: 'Select Active Day',
    input_extra_amount: 'Enter Extra Amount',
    input_starting_amount: 'Input Starting Amount',
    start_from: 'Start from',
    the_next_day: 'The next day', // ngay hom sau
    select_action_time: 'Select Action Time',
    input_suggest_percent: 'Enter Recommend Percent',
    input_enough: 'Enter Full Data',
    fill_input: 'Fill in the inputs',
    auto_bid_price_note: 'You can only use one configuration. Auto Position Configuration will be deleted. Do you want to be continue?', // Bạn chỉ có thể sử dụng 1 trong 2 cấu hình tự động. Nếu bạn chọn cấu hình đấu thầu tự động thì cấu hình vị trí sẽ bị xóa. Bạn có muốn tiếp tục?
    auto_position_note2: 'You can only use one configuration. Auto Bid Price Configuration will be deleted. Do you want to be continue?', // `Bạn chỉ có thể sử dụng 1 trong 2 cấu hình tự động. Nếu bạn chọn cấu hình vị trí tự động thì cấu hình đấu thầu tự động sẽ bị xóa. Bạn có muốn tiếp tục?
    export_data_error: 'Error, please wait for a few minutes before resuming the export',
    export_data_error_2_1: 'An error occurred when exporting data - Step 2.1', // Đã có lỗi khi tải báo cáo - Bước 2.1
    export_data_error_2_2: 'An error occurred when exporting data - Step 2.2', // Đã có lỗi khi tải báo cáo - Bước 2.1
    low_balance_msg: 'Your ads credit balance is low, please top up to ensure that your promotion won\'t be interrupted', // Số dư tài khoản quảng cáo thấp. Vui lòng nạp thêm tiền để đảm bảo các chương trình quảng cáo không bị gián đoạn.
    finished_loading_data: 'Finished Loading Data',
    shopee_update_msg: 'Shopee\'s system is in the process of updating. This will cause errors for Atosa. Please wait a few minutes.', // Hệ thống Shopee đang trong quá trình cập nhật, điều này sẽ gây ra lỗi đối với Atosa. Quý khách xin vui lòng đợi trong ít phút
    shop_ads_error: 'Sorry, you are currently not eligible to use Shop Search Ads', // Hiện tại Shop không đủ điều kiện để sử dụng Quảng cáo Tìm Kiếm Shop
    invalid_start_time: 'Invalid Start Time', // 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
    budget_needs_more: 'Budget needs to be more than ',
    create_ads_confirm: 'Do you want create this ads?',
    select_keyword: 'Select Keyword',
    create_ads_success: 'Create New Ads Successfully',
    delete_success: 'Delete Successfully',
    time: 'Time',
    config_auto: 'Config Automation',
    bulk_set_anti_click: 'Bulk Anti Click Setting', // Cài đặt chống click ảo hàng loạt
    bulk_set_auto_cir: 'Bulk Auto Optimize CIR Setting', // Tối ưu CIR tự động hàng loạt
    delete_configuration: 'Delete Configuration', // Xóa cài đặt tự động
    bulk_delete_configuration: 'Bulk Auto Configuration', // Xóa cài đặt tự động
    delete_anti_click: 'Delete Anti Click Configuration',
    delete_auto_cir: 'Delete Auto CiR Configuration',
    change_keyword: 'Change Keyword',
    change_keyword_type: 'Change Keyword Type',
    change_keyword_status: 'Change Keyword Status',
    exact_keyword: 'Exact Keyword',
    broad_keyword: 'Broad Keyword',
    auto_bid_price_running: 'Auto Bid Price is Running',
    auto_position_running: 'Auto Position is Running',
    anti_click_running: 'Anti Click is Running',
    auto_cir_running: 'Auto CIR is Running',
    not_set_auto: 'Not Set Auto',
    not_set_anti_click: 'Not Set Anti Click',
    export_keyword_file: 'Export Keyword File',
    export_selected_keyword_file: 'Export Selected Keyword File',
    export_shopee_data: 'Export Shopee Data', // Tải báo cáo Shopee
    select_n_keyword_on_page: 'Select {number} keywords on current page', // Chọn {{ keyword_filter.page_size }} từ khóa trên trang hiện tại
    auto_selected: 'Auto Selected', // Quảng cáo tự động
    restore: 'Restore', // Khôi phục,
    per_click: 'Per Click', // Gia thau,
    pin: 'Pin',
    unpin: 'Unpin',
    batch_position_check: 'Batch Position Check', // Kiem tra vi tri hang loat
    setting: 'Setting', // Cai dat
    setup_automation: 'Setup Automation', // cai dat tu dong
    installed: 'Installed',
    not_installed: 'Not installed yet',
    setup_anti_click: 'Setup Anti Click',
    setup_auto_cir: 'Setup Auto CIR',
    check_position: 'Check', // Kiem tra
    average_ranking: 'Average Ranking', // Thu hang TB
    suggested: 'Suggested', // Gia goi y
    shopee_suggested: 'Shopee Suggested', // Shopee de xuat
    atosa_keyword_tool: 'Atosa Keyword Tool',
    search_keyword: 'Search Keyword',
    select_keyword_file: 'Select Keyword File',
    select_file: 'Select file', // Chon file
    import_file_note: 'Please save keywords on first column, bid price on second column (bid price is not required)', // Hãy lưu từ khóa ở cột đầu tiên, giá thầu ở cột thứ hai (Giá thầu không bắt buộc)
    add_keywords: 'Added Keywords',
    add_all_keyword: 'Add all keywords',
    add_top_keyword: 'Add top {number} keywords',
    remove_duplicated_keyword: 'Remove duplicated keywords', // Loại bỏ các từ khóa bị trùng
    confirm_and_add_keywords: 'Confirm and Add Keywords', // Xác nhận và thêm từ khóa
    bulk_bid_price_update: 'Bulk Bid Price Update', // Điều chỉnh giá thầu đồng loạt
    i_r_by_shopee_amount: 'Increase/decrease by Amount of Shopee Recommend Price or CPC', // Tăng / giảm giá thầu theo số tiền của Giá gợi ý hoặc CPC
    i_r_by_shopee_percent: 'Increase/decrease by Percent of Shopee Recommend Price or CPC', // Tăng / giảm giá thầu theo % của Giá gợi ý hoặc CPC
    by_recommend_price: 'By Shopee Recommend Price or CPC',
    update_keyword: 'Update Keyword',
    update_keyword_type: 'Update Keyword Type',
    update_keyword_status: 'Update Keyword Status',
    bulk_keyword_update: 'Update Keyword',
    bulk_keyword_type_update: 'Update Keyword Type',
    bulk_keyword_status_update: 'Update Keyword Status',
    running: 'Running',
    convert_table: 'Location conversion table between Web and App Shopee', // Bảng quy đổi vị trí giữa Web và App Shopee
    web_position: 'Web Position',
    mobile_position: 'Mobile Position',
    added_keywords: 'Added Keywords',
    rate_of: 'Rate of',
    minimum_clicks: 'Minimum Clicks', // Số lượt click tối thiểu
    maximum_clicks: 'Maximum Clicks', // Số lượt click tối đa
    cir_rate: 'CIR %', // Tỉ lệ % CIR
    maximum_cost: 'Maximum Cost',
    input_maximum_cost: 'Enter Maximum Cost',
    not_required: 'Not Required',
    select_test_time: 'Select Test Time',
    check_if_no_order: 'Check when there is no order', // Kiểm tra khi không có đơn hàng
    gmv_is_0: 'GMV is 0', // (GMV bằng 0)
    maximum_cost_no_order: 'Set Maximum Cost when there is no order', // Chi phí tối đa khi không có đơn hàng
    anti_click_action: 'Take action when being Anti Click', // Hành động khi phát hiện click ảo
    broad_cir: 'Broad CIR',
    cir_gte_0: 'CIR must be greater than 0',
    minimum_clicks_gte_0: 'Minimum Clicks must be greater than 0',
    maximum_clicks_gte_0: 'Maximum Clicks must be greater than 0',
    rate_value_gte_0: 'Rate Value must be greater than 0',
    input_maximum_cost_no_order: 'Enter Maximum Cost Value when there is no order',
    delete_selected_items_confirm: 'Do you want delete items you selected?',
    input_cir: 'Enter CIR value',
    select_cir_type: 'Select CIR Type',
    select_1_option: 'You must select at least 1 option',
    save_confirm: 'Do you want to save this',
    input_bid_price: 'Enter Bid Price', //
    cant_delete_all: 'Can not delete all keywords, you must leave at least 1 keyword',
    delete_keyword_confirm: 'Do you want delete this keyword "{keyword}"?',
    delete_keywords_confirm: 'Do you want delete these keywords?',
    delete_config_confirm: 'Do you want delete this configuration?',
    delete_configs_confirm: 'Do you want delete these configurations?',
    add_1_keyword: 'Please add at least 1 keyword', // Vui lòng chọn ít nhất 1 từ khóa để thêm
    keyword_exceeds: 'The number of keywords is not allowed to exceed {n1} words. Number of current keywords {n2}',
    bid_price_gte_0: 'Bid Price must be greater than 0',
    contain_broad_keyword: 'Note: In Auto Configuration List containt Broad Keyword, do you want to be continue?', // Lưu ý: Trong danh sách cấu hình có từ khóa mở rộng. Bạn có muốn tiếp tục
    no_keyword_to_export: 'Have no keywords to export',
    loading_data_error: 'An error occurred when loading data',
    saved_bid_price: 'Saved Bid Price',
    use_saved_bid_price: 'Use Saved Bid Price',
    allow_delete_old_keywords: 'Allow Delete Old Keywords',
    confirm_and_create_new_ads: 'Confirm and Create New Ads',
    cant_delete_this_product: 'Can not delete this product',
    ads_name: 'Ads Name', // Ten QC
    input_ads_name: 'Enter Ads Name',
    auto_maintain_display_running: 'Auto Maintain Ranking is Running',
    update_campaign: 'Update Campaign',
    tag_line: 'Tag Line', // Khẩu hiệu quảng cáo (Tagline)
    input_tag_line: 'Enter Tag Line',
    tag_line_not_contain: 'Tag Line can not contain ', // Khẩu hiệu quảng cáo của bạn không thể chứa
    link_shop: 'Link Page', // Trang lien ket
    home_page: 'Home Page (Default)', // 'Trang chủ Shop (Mặc định)
    category_page: 'Category Page', // Trang danh mục Shop
    select_category: 'Select Category',
    error_code: 'Error code: ',
    ads_name_in_used: 'Ads Name is existed', // 'Tên chiến dịch đã được sử dụng'
    profile: 'Profile', // Thong tin tai khoan,
    update_profile_msg: 'Please update your profile so that We can assist you', //  Vui lòng cập nhật thông tin của bạn để chúng tôi có thể hỗ trợ khi có vấn đề hoặc khi quên mật khẩu.
    update_profile_confirm: 'Do you want to update your profile?', // Bạn có chắc muốn cập nhật thông tin cá nhân của mình không?
    update_password_confirm: 'Do you want update new password? You will have to re-login.', // Cập nhật mật khẩu sẽ phải đăng nhập lại, bạn có chắc vẫn muốn thay đổi chứ?
    current_password_not_correct: 'Current Password is not correct', // Mật khẩu hiện tại không chính xác, vui lòng kiểm tra và thử lại sau!
    confirm_password_not_correct: 'Confirm Password is not correct',
    invalid_phone_number: 'Invalid Phone Number',
    invalid_email: 'Invalid Email',
    invalid_display_name: 'Invalid Display Name',
    input_budget: 'Enter Budget',
    final_bid_price: 'Final Bid Price Range', // Khoảng giá thầu cuối cùng
    display_locations: 'Display Locations', // Vị trí hiển thị
    placement_1: 'Product Detail Page-Similar Products', // Trang chi tiết sản phẩm - Sản phẩm tương tự
    placement_2: 'Homepage-Daily Discover', // Trang chủ - Gợi ý hôm nay
    placement_5: 'Product Details Page-You May Also Like', // Trang chi tiết sản phẩm - Có thể bạn cũng thích
    auto_optimisation: 'Auto Optimisation', // Quang cao tu dong
    auto_premium: 'Auto Premium',
    premium_rate: 'Premium Rate',
    update_premium_rate: 'Update Premium Rate',
    bid_price_gte: 'Bid Price must be greater than {value}',
    input_premium_rate: 'Enter premium rate',
    premium_rate_gte_0: 'Premium rate must be greater than 0',
    premium_rate_lte_300: 'Premium rate must be less than 300',
    setup_new_ads: 'Setup New Ads',
    setup: 'Setup', // Thiet lap
    permission_error_1: 'Permission Error: Feature not found', // Lỗi phân quyền: Không tìm thấy tính năng
    permission_error_2: 'Permission Error: Action not found', // Lỗi phân quyền: Không tìm thấy hành động
    permission_error_3: 'You do not have permission to access the feature', // Bạn không có quyền truy cập tính năng
    need_apply_package_msg: 'You need apply Shopee Marketing Package to use this feature.', // Quý khách cần áp dụng gói để sử dụng tính năng này.
    related_product_link: 'Related Product Link', // Link sản phẩm liên quan
    your_product: 'Your Product', //
    search_product_link: 'Search Product Link', // Đường dẫn sản phẩm tìm kiếm (Sản phẩm của đối thủ)
    add_product: 'Add Product',
    search_type: 'Search Type',
    daily_discover: 'Daily Discover', // Gợi ý hôm nay
    found_position: 'Found Position',
    check_position_discovery_ads: 'Check Position Discovery Ads',
    related_product: 'Related Product',
    you_may_also_like: 'You May Also Like',
    product_not_found: 'Product Not Found',
    learn_more_atosa: 'Learn more about the Safest Shopee Ads Support Tool',
    here: 'here',
    limited_shop: 'Limited number of shops!',
    waiting_for_otp: 'Waiting for verify OTP on Mobile Device or Email inbox. Confirmation time is {second} seconds.',
    cant_verify_otp: 'Can not verify OTP',

    platform: 'Platform',
    advance: 'Advance',
    display: 'Display',
    pricing: 'Pricing',
    about_us: 'About us',
    views: 'Views',
    likes: 'Likes',
    comments: 'Comments',
    shares: 'Shares',
    last_seen: 'Last seen',
    released_date: 'Released Date',
    sort_by: 'Sort by ',
    day_s: 'day (s)',
    week_s: 'week (s)',
    month_s: 'month (s)',
    country: 'Country',
    latest_video: 'Latest View',
    results_per_page: '{number} result(s) per page',
    up_to_results: 'Up to {number} results',
    or: 'or',
    enter: 'Enter',
    download: 'Download',
    title: 'Title',
    all_features: 'All features are included',
    enjoin_all: 'Enjoy All The Features',
    results: 'Results',
    choose_plan: 'Choose Plan',
    unlimited: 'Unlimited',
    unlimited_download: 'Unlimited video downloads',
    and_many_more: 'and many more utilities',
    our_best_pricing: 'Our Best Pricing',
    invalid_license: 'Invalid license',
    query_history: 'Query History',
    forbidden: '403 Forbidden Error',
    should_login: 'Login Your Account To Use All Features',
    upgrade_license: 'Upgrade Your License For A Better Experiences',
    info: 'Information',
    video_detail: 'Video Detail',
    video_location: 'Video Location',
    author_link: 'Author Link',
    updated_time: 'Updated Time',
    renewal: 'Renewal',
    require_password: 'Password is required to update profile',
    re_login_msg: 'Require re-login after changing the password',
    ads_video: 'Ads Video',
    download_video: 'Download video',
    copied: 'Copied!'
  }
}

const i18n = new VueI18n({
  locale: 'vi', // set locale
  fallbackLocale: 'vi', // set fallback locale
  messages // set locale messages
})

export default i18n
