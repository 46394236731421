import moment from 'moment'
import Chart from 'chart.js'
import { Line } from 'vue-chartjs'

const draw = Chart.controllers.line.prototype.draw
Chart.controllers.line = Chart.controllers.line.extend({
  draw: function () {
    draw.apply(this, arguments)

    if (this.chart.tooltip._active && this.chart.tooltip._active.length && this.chart.scales['y-axis-1']) {
      const activePoint = this.chart.tooltip._active[0]
      const ctx = this.chart.ctx
      const x = activePoint.tooltipPosition().x
      const topY = this.chart.scales['y-axis-1'].top
      const bottomY = this.chart.scales['y-axis-1'].bottom

      // draw line
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = 1
      ctx.strokeStyle = '#ccccd6'
      ctx.stroke()
      ctx.restore()
    }
  }
})

export default {
  name: 'log__line_chart',

  extends: Line,

  props: {
    // key_list: null,
    // selected_key_list: null,
    reports: null
  },

  data () {
    return {
      key_list: {
        playCount: {
          label: 'Views',
          name: 'playCount',
          color: 'rgb(54, 127, 227)',
          show: true
        },
        diggCount: {
          label: 'Likes',
          name: 'diggCount',
          color: 'rgb(246, 189, 22)',
          show: true
        },
        commentCount: {
          label: 'Comments',
          name: 'commentCount',
          color: 'rgb(35, 170, 171)',
          show: true,
          percent: true
        },
        shareCount: {
          label: 'Shares',
          name: 'shareCount',
          color: 'rgb(88, 183, 241)',
          show: true
        }
      },

      selected_key_list: ['playCount', 'diggCount', 'commentCount', 'shareCount'],

      chart_data: {
        labels: [],
        datasets: []
      },
      options: {
        layout: { padding: { left: -1000 } },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          titleFontSize: 12,
          bodyFontSize: 12,
          enabled: true,
          mode: 'label',
          intersect: false,
          callbacks: {
            title: function (tooltipItems) {
              if (tooltipItems[0] && tooltipItems[0].xLabel) {
                return tooltipItems[0].xLabel
                  .replace(/^.+\(/, '')
                  .replace(/[()]/ig, '')
              } else {
                return ''
              }
            },
            label: function (tooltipItems, data) {
              const value = tooltipItems.yLabel % 1 === 0
                ? tooltipItems.yLabel.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
                : tooltipItems.yLabel.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
              return data.datasets[tooltipItems.datasetIndex].label + ': ' + value
            }
          }
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 8,
                maxRotation: 0,
                minRotation: 0
              }
            }
          ],
          yAxes: [
            {
              id: 'y-axis-1',
              position: 'left',
              display: true,
              ticks: {
                display: false,
                beginAtZero: true,
                minTicksLimit: 6,
                maxTicksLimit: 6
              },
              gridLines: {
                display: true,
                borderDash: [8, 4],
                color: 'rgb(229,229,229)'
              }
            },
            {
              id: 'y-axis-2',
              position: 'left',
              ticks: {
                display: false,
                beginAtZero: true,
                maxTicksLimit: 6
              },
              gridLines: {
                display: false
              }
            },
            {
              id: 'y-axis-3',
              position: 'left',
              ticks: {
                display: false,
                beginAtZero: true,
                maxTicksLimit: 6
              },
              gridLines: {
                display: false
              }
            },
            {
              id: 'y-axis-4',
              position: 'left',
              ticks: {
                display: false,
                beginAtZero: true,
                maxTicksLimit: 6
              },
              gridLines: {
                display: false
              }
            },
          ]
        },
        hover: {
          mode: 'nearest',
          intersect: false,
          axis: 'xy'
        }
      },
      dataset_template: {
        label: 'Chỉ số',
        data: [40, 65, 80, 81, 56, 55],
        point: '0',
        type: 'line',
        pointBorderWith: 0,
        lineTension: 0.3,
        showLines: true,
        backgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        pointHoverBorderWidth: 0,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        borderColor: 'rgb(54, 127, 227)',
        yAxisID: 'y-axis-1',
        pointRadius: 0,
        borderWidth: 2
      }
    }
  },

  mounted () {
    this.m__render_chart()
  },

  watch: {
    // selected_key_list: function () {
    //   this.m__render_chart()
    // },

    reports: function () {
      this.m__render_chart()
    }
  },

  methods: {
    m__generate_label () {
      // const first = moment(this.reports[0].timestamp * 1000)
      // const last = moment(this.reports[this.reports.length - 1].timestamp * 1000)
      // const diff = last.diff(first, 'day')
      // let format = 'HH:mm'
      // if (diff > 7) format = 'DD/MM'
      // if (diff <= 7) format = 'HH:mm DD/MM'
      // if (diff === 0) format = 'HH:mm'
      //
      // if ([0, 1, 3].includes(diff)) this.options.scales.xAxes[0].ticks.maxTicksLimit = 8
      // if ([2].includes(diff)) this.options.scales.xAxes[0].ticks.maxTicksLimit = 6
      // if ([4].includes(diff)) this.options.scales.xAxes[0].ticks.maxTicksLimit = 5
      // if ([5].includes(diff)) this.options.scales.xAxes[0].ticks.maxTicksLimit = 6
      // if ([6, 7, 8].includes(diff)) this.options.scales.xAxes[0].ticks.maxTicksLimit = 7
      // if (diff > 8) this.options.scales.xAxes[0].ticks.maxTicksLimit = 8
      // // if (diff > ) this.options.scales.xAxes[0].ticks.maxTicksLimit = 8

      const result = []
      for (const item of this.reports) {
        result.push(moment(new Date(item.time)).format('DD/MM/YYYY'))
      }

      this.chart_data.labels = result
    },

    m__render_chart () {
      if (!this.reports || !this.reports.length) {
        this.chart_data.labels = []
        this.chart_data.datasets = []
      } else {
        this.m__generate_label()
        this.chart_data.datasets = []
        let index = 1
        for (const key of this.selected_key_list) {
          const dataset = { ...this.dataset_template }
          dataset.data = []
          dataset.label = this.key_list[key].label
          dataset.borderColor = this.key_list[key].color
          dataset.pointBackgroundColor = this.key_list[key].color
          dataset.yAxisID = `y-axis-${index++}`
          for (const item of this.reports) {
            dataset.data.push(item[key])
          }
          this.chart_data.datasets.push(dataset)
        }
      }
      this.renderChart(this.chart_data, this.options)
    }
  }
}
