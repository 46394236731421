import 'element-ui/lib/theme-chalk/index.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import bus, { Bus } from './shared/bus'
import Utils from './shared/utils'
import Filter from './shared/filter'
import Http from './shared/http'
import i18n from './shared/lang'
import ElementUI from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'

Vue.config.productionTip = false

Vue.use(ElementUI, { locale: enLocale })
Vue.use(bus)
Vue.use(Filter)
Vue.use(Http)
Vue.use(Utils)

const EB = Bus
Vue.prototype.EB = Bus

Vue.prototype.language_list = [
  {
    name: 'Vietnamese',
    key: 'vi',
    lang: 'vi',
    flag: require('@/assets/flags/vi.png')
  },
  {
    name: 'English',
    key: 'en',
    lang: 'en',
    flag: require('@/assets/flags/uk.png')
  }
]

Vue.prototype.ntf_e = (data) => {
  EB.$emit('NTF_error', data)
}
Vue.prototype.ntf_w = (data) => {
  EB.$emit('NTF_warning', data)
}
Vue.prototype.ntf_s = (data) => {
  EB.$emit('NTF_success', data)
}
Vue.prototype.ntf_i = (data) => {
  EB.$emit('NTF_info', data)
}

Vue.prototype.cf = function () {
  if (typeof store.state.token === 'undefined') {
    return {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  } else {
    return {
      headers: {
        'content-type': 'application/json',
        authorization: store.state.token
      }
    }
  }
}

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  beforeMount () {
    this.EB.$on('quick_confirm', data => {
      this.$confirm(data.message, this.$t('warning'), {
        type: 'warning',
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(() => {
          data.callback()
        })
        .catch(error => {
          console.log('ERROR_CALLBACK==================================')
          console.log(error)
        })
    })

    this.EB.$on('NTF_error', data => {
      this.$notify({
        type: 'error',
        title: this.$t('error'),
        message: typeof data === 'string' ? data : this.translate_message(data.data.message) || this.$t('system_error')
      })
    })

    this.EB.$on('NTF_success', data => {
      this.$notify({
        type: 'success',
        title: this.$t('success'),
        message: typeof data === 'string' ? data : this.translate_message(data.data.message) || this.$t('successfully')
      })
    })

    this.EB.$on('NTF_warning', data => {
      this.$notify({
        type: 'warning',
        title: this.$t('warning'),
        message: typeof data === 'string' ? data : (data.data && data.data.message ? this.translate_message(data.data.message) : this.$t('system_error'))
      })
    })

    this.EB.$on('CFM_warning', data => {
      this.$confirm(this.translate_message(data), this.$t('warning'), {
        showConfirmButton: false,
        showCancelButton: false,
        type: 'warning'
      })
        .then(null)
        .catch(null)
    })
  }
}).$mount('#app')
