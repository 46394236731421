<template>
  <div style="padding-top: 0.5rem">
    <div class="container">
      <div class="row justify-content-center pb-3">
        <div class="col-md-7 text-center heading-section">
          <h2 class="mb-3">{{ $t('saved_file_list') }}</h2>
        </div>
      </div>
      <div class="result-section">
        <div class="result-section-list">
          <div v-for="item in saved_list" :key="item.id" class="result-section-item">
            <div class="save" @click="m__delete_save(item)">
              <i class="el-icon-delete" aria-hidden="true"></i>
            </div>
            <div class="header-section">
              <div class="avatar">
                <img :src="item.avatarPath ? $api.URL_API_SRV_8_STATIC + item.avatarPath : item.author.avatarThumb"
                     alt="test">
              </div>
              <div class="title" @click="open_link(`https://tiktok.com/@${item.author.uniqueId}`)">
                {{ item.author.nickname }}
              </div>
            </div>
            <div class="video-section">
              <img :src="item.imagePath ? $api.URL_API_SRV_8_STATIC + item.imagePath : item.video.cover"
                   alt="test" class="filter">
              <video controls="controls" controlslist="nodownload" oncontextmenu="return false"
                     :src="item.videoPath ? $api.URL_API_SRV_8_STATIC + item.videoPath : item.video.playAddr"></video>
            </div>
            <div class="name-section">
              <div class="name">
                {{ item.desc }}
              </div>
              <div class="detail">
                <div class="link">
                  <a :href="`https://m.tiktok.com/v/${item.id || item.tiktokId}.html`" target="_blank">
                    https://m.tiktok.com/v/{{ item.id || item.tiktokId }}.html
                  </a>
                </div>
                <div>
                  <el-button size="small" @click="$router.push(`/detail/tiktok/${item.id || item.tiktokId}`)">
                    {{ $t('detail') }} >>
                  </el-button>
                </div>
              </div>
            </div>
            <div style="padding: 0 4px;">
              <p style="border-top: 2px solid rgb(233, 233, 233);"></p>
            </div>
            <div class="info-section">
              <div class="info-section-list">
                <div class="info-section-item">
                  <div class="value">
                    {{ short_value(item.stats.playCount) }}
                  </div>
                  <div class="title">
                    Views
                  </div>
                </div>
                <div class="info-section-item">
                  <div class="value">
                    {{ short_value(item.stats.diggCount) }}
                  </div>
                  <div class="title">
                    Likes
                  </div>
                </div>
                <div class="info-section-item">
                  <div class="value">
                    {{ short_value(item.stats.commentCount) }}
                  </div>
                  <div class="title">
                    Comments
                  </div>
                </div>
              </div>
              <div class="info-section-list">
                <div class="info-section-item">
                  <div class="value">
                    {{ short_value(item.stats.shareCount) }}
                  </div>
                  <div class="title">
                    Shares
                  </div>
                </div>
                <div class="info-section-item">
                  <div class="value">
                    {{ item.challenges && item.challenges.length ? item.challenges.length : 0 }}
                  </div>
                  <div class="title">
                    Hashtags
                  </div>
                </div>
                <div class="info-section-item">
                  <div class="value">
                    {{ item.createTime * 1000 | moment('DD/MM/YYYY') }}
                  </div>
                  <div class="title">
                    Release time
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'saved-component',

  data () {
    return {
      saved_list: []
    }
  },

  async beforeMount () {
    await this.m__get_saved_data()
  },

  methods: {
    async m__get_saved_data () {
      if (!this.$store.state.token) {
        this.EB.$emit('require_login')
        return
      }

      const response = await this.$http.get(this.$api.URL_API_SRV_8 + '/get_saved_data', this.cf())
      this.saved_list = response.data.data
    },

    async m__delete_save (data) {
      try {
        await this.$confirm(this.$t('delete_any_data_confirm'), 'Info', {
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('cancel'),
          type: 'info'
        })
      } catch (e) {
        return
      }

      try {
        await this.$http.delete(this.$api.URL_API_SRV_8 + '/delete_saved?item_id=' + data.tiktokId, this.cf())
        await this.m__get_saved_data()
        this.ntf_s(this.$t('update_successfully'))
      } catch (e) {
        console.error(e)
        this.ntf_w(e)
      }
    }
  }
}
</script>
