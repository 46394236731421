import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const state = {
  userInfo: {},
  token: '',
  shop: [],
  ip_change_tutorial: true,
  new_order: 0,
  socket_connected: false,
  sync_mode: false,
  registered_alert: false,
  license: [],
  buyer_mode: false,
  trial_mode: true,
  package: [],
  advanced_ads_list: [],
  agency_plan_list: [],
  region: '',
  lang: ''
}

const mutations = {
  UPDATE_IP_TUTORIAL (state, _tutorial_id) {
    state.ip_change_tutorial = _tutorial_id
  },
  UPDATE_TRIAL_MODE (state, _trial_mode) {
    state.trial_mode = _trial_mode
  },
  UPDATE_LICENSE (state, _license) {
    state.license = _license
  },
  UPDATE_BUYER_MODE (state, _buyer_mode) {
    state.buyer_mode = _buyer_mode
  },
  UPDATE_SYNC_MODE (state, _sync_node) {
    state.sync_mode = _sync_node
  },
  USERINFO (state, _userinfo) {
    if (typeof _userinfo !== 'undefined') {
      state.userInfo = _userinfo
    }
  },
  SET_TOKEN (state, _token) {
    if (typeof _token !== 'undefined') {
      state.token = _token
    }
  },
  SET_PACKAGE (state, _data) {
    if (typeof _data !== 'undefined') {
      state.package = _data
    }
  },
  UPDATE_SHOP (state, _shop) {
    state.shop = _shop
  },
  UPDATE_NEW_ORDER (state, _new_order) {
    state.new_order = _new_order
  },
  UPDATE_SOCKET_CONNECTED (state, _socket_connected) {
    state.socket_connected = _socket_connected
  },
  UPDATE_REGISTERED_ALERT (state, _registered_alert) {
    state.registered_alert = _registered_alert
  }
}

const actions = {
  set__buyer_mode ({ commit }, _buyer_node) {
    commit('UPDATE_BUYER_MODE', _buyer_node)
  },
  set__license ({ commit }, _license) {
    commit('UPDATE_LICENSE', _license)
  },
  set__registered_alert ({ commit }, _registered_alert) {
    commit('UPDATE_REGISTERED_ALERT', _registered_alert)
  },
  set__sync_mode ({ commit }, _sync_mode) {
    commit('UPDATE_SYNC_MODE', _sync_mode)
  },
  set__user_info ({ commit }, _userInfo) {
    commit('USERINFO', _userInfo)
  },
  set__token ({ commit }, _token) {
    commit('SET_TOKEN', _token)
  },
  set__package ({ commit }, _data) {
    commit('SET_PACKAGE', _data)
  },
  set__shop ({ commit }, _shop) {
    commit('UPDATE_SHOP', _shop)
  },
  set__new_order ({ commit }, _new_order) {
    commit('UPDATE_NEW_ORDER', _new_order)
  },
  set__socket_connected ({ commit }, _socket_connected) {
    commit('UPDATE_SOCKET_CONNECTED', _socket_connected)
  },
  set__trial_mode ({ commit }, _trial_mode) {
    commit('UPDATE_TRIAL_MODE', _trial_mode)
  },
  set__ip_tutorial ({ commit }, _tutorial) {
    commit('UPDATE_IP_TUTORIAL', _tutorial)
  },
  update_userInfo ({ commit }, _userInfo) {
    commit('USERINFO', _userInfo)
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [createPersistedState({
    key: '_ss',
    storage: {
      getItem: key => localStorage.getItem(key),
      setItem: (key, value) => localStorage.setItem(key, value),
      removeItem: key => localStorage.removeItem(key)
    }
  })]
})
