<template>
  <div style="padding-top: 0.5rem">
    <div class="container">
      <div class="row justify-content-center pb-3">
        <div class="col-md-7 text-center heading-section">
          <h2 class="mb-3">{{ msg ? $t(msg) : $t('our_best_pricing') }}</h2>
        </div>
      </div>
      <div class="row d-flex package-item">
        <div v-for="pack in package_list" :key="pack._id" class="col-lg-3 col-md-6">
          <div class="block-7">
            <div class="text-center">
              <h2 class="heading"
                  :class="{'text-success': current_package && current_package.package && current_package.package._id === pack._id}">
                {{ pack.description }}
              </h2>
              <span class="price"><sup>₫</sup> <span class="number">{{ short_value(pack.price) }}<small
                class="per">/{{ $t('month') }}</small></span></span>
              <span class="excerpt d-block">{{ pack.name }}</span>
              <h3 class="heading-2 mb-3">{{ $t('enjoin_all') }}</h3>

              <ul class="pricing-text mb-4">
                <li>
                  <strong>{{ pack.extra_data.max_results }}</strong> &nbsp;
                  <span style="font-size: 14px">{{ $t('results') }}/Request</span>
                </li>
                <li>
                  <strong>{{ pack.extra_data.requests_per_day }}</strong> &nbsp;
                  <span style="font-size: 14px">Requests/{{ $t('day') }}</span>
                </li>
                <li>
                  {{ $t('unlimited_download') }}
                </li>
                <li>{{ $t('and_many_more') }}</li>
              </ul>
              <a href="javascript:void(0)" class="btn btn-primary d-block px-3 py-3 mb-4"
                 @click="m__buy_package(pack._id)">
                {{ $t('choose_plan') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pricing-component',

  data () {
    return {
      msg: '',
      package_list: [],
      current_package: null
    }
  },

  async beforeMount () {
    this.msg = this.$route.query.msg

    await this.m__get_packages()
    await this.m__get_current_package()
  },

  methods: {
    async m__get_current_package () {
      if (this.$store.state.token) {
        const current_package_response = await this.$http.get(this.$api.store_get_current_package, this.cf())
        if (current_package_response && current_package_response.data) {
          this.current_package = current_package_response.data.data.extend_package.find(i => i.channel === 'atosa_spy')
        }
      }
    },

    async m__buy_package (pack_id) {
      if (!this.$store.state.token) {
        this.EB.$emit('require_login')
        return
      }

      window.open(`https://banhang.atosa.asia/authenticate/handle?redirect=%2Fpayment%2Ftransfer%3Fchannel%3Datosa_spy%26package_id%3D${pack_id}%26quantity%3D3%26voucher_code%3D&token=${this.$store.state.token}`, '_blank')
    },

    async m__get_packages () {
      this.package_list = []
      try {
        const response = await this.$http.get(this.$api.URL_API_SRV_8 + '/get_packages')
        this.package_list = response.data.data
      } catch (e) {
        console.error()
      }
    }
  }
}
</script>

<style>
.package-item {
  justify-content: center;
}

.package-item > div {
  margin: 0 15px;
  padding: 0 0;
}
</style>
