<template>
  <div style="padding-top: 0.5rem">
    <div class="container">
      <div class="profile-wrapper">
        <div class="user-info">
          <p class="title">{{ $t('profile') }}</p>

          <div class="item">
            <div class="label">{{ $t('username') }}</div>
            <div class="value">{{ user_info?.username }}</div>
          </div>
          <div class="item">
            <div class="label">{{ $t('email') }}</div>
            <div class="value">{{ user_info?.email }}</div>
          </div>
          <div class="item">
            <div class="label">{{ $t('display_name') }}</div>
            <div class="value">
              <el-input class="fixed-input" :placeholder="$t('enter') + ' '+ $t('display_name')"
                        v-model="user_info.display_name"></el-input>
            </div>
          </div>
          <div class="item">
            <div class="label">{{ $t('password') }}</div>
            <div class="value">
              <el-input class="fixed-input" :placeholder="$t('enter') + ' '+ $t('password')"></el-input>
            </div>
          </div>

          <div class="submit">
            <div>
              <span class="text-info">*{{ $t('require_password') }}</span>
            </div>
            <div>
              <el-button type="primary">{{ $t('update') }}</el-button>
            </div>
          </div>
        </div>
        <div v-if="current_package" class="package-info">
          <p class="title">{{ $t('package') }}</p>

          <div class="info">
            <div class="text-primary">{{ current_package?.package?.description }}</div>
            <div>
              {{ short_value(current_package?.package?.price) }}<sup>₫</sup>
              &nbsp;<small style="font-size: 12px" class="per">/{{ $t('month') }}</small>
            </div>
          </div>

          <div style="font-size: 18px; margin-bottom: 20px">
            <strong>{{ current_package?.package?.extra_data.max_results }}</strong> &nbsp;
            <span style="font-size: 14px">{{ $t('results') }}/Request</span>
          </div>

          <div style="font-size: 18px; margin-bottom: 20px">
            <strong>{{ current_package?.package?.extra_data.requests_per_day }}</strong> &nbsp;
            <span style="font-size: 14px">Requests/{{ $t('day') }}</span>
          </div>

          <div style="font-size: 16px; margin-bottom: 20px; font-weight: 600">
            {{ $t('license_to') }}:&nbsp;&nbsp;
            <span :class="m__is_expr(current_package?.license_to) ? 'text-danger' : 'text-success'">
              {{ current_package?.license_to | moment('DD/MM/YYYY HH:mm') }}
            </span>
          </div>

          <!--          <div style="font-size: 18px; margin-bottom: 20px; font-weight: 600">
                      {{ $t('unlimited_download') }}
                    </div>

                    <div style="font-size: 18px; margin-bottom: 20px; font-weight: 600">
                      {{ $t('and_many_more') }}
                    </div>-->

          <div style="position: absolute; bottom: 20px; right: 40px">
            <el-button type="primary" @click="$router.push('/pricing')">{{ $t('renewal') }}</el-button>
          </div>
        </div>
      </div>

      <div class="password-wrapper">
        <div class="content">
          <div class="title">{{ $t('change_password') }}</div>

          <div class="item">
            <div class="label">{{ $t('current_password') }}</div>
            <div class="value">
              <el-input class="fixed-input" :placeholder="$t('enter') + ' '+ $t('current_password')"></el-input>
            </div>
          </div>

          <div class="item">
            <div class="label">{{ $t('new_password') }}</div>
            <div class="value">
              <el-input class="fixed-input" :placeholder="$t('enter') + ' '+ $t('new_password')"></el-input>
            </div>
          </div>

          <div class="item">
            <div class="label">{{ $t('confirm_password') }}</div>
            <div class="value">
              <el-input class="fixed-input" :placeholder="$t('enter') + ' '+ $t('confirm_password')"></el-input>
            </div>
          </div>

          <div class="submit">
            <div>
              <span class="text-info">*{{ $t('re_login_msg') }}</span>
            </div>
            <div>
              <el-button type="primary">{{ $t('update') }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'profile-component',

  data () {
    return {
      user_info: {},
      current_package: {}
    }
  },

  async beforeMount () {
    if (!this.$store.state.token) {
      this.$router.push('/')
      this.EB.$emit('require_login')
      return
    }

    this.user_info = { ...this.$store.state.userInfo }
    await this.m__get_current_package()
  },

  methods: {
    m__is_expr (date) {
      return moment(new Date(date)).isBefore(moment())
    },

    async m__get_current_package () {
      if (this.$store.state.token) {
        const current_package_response = await this.$http.get(this.$api.store_get_current_package, this.cf())
        if (current_package_response && current_package_response.data) {
          this.current_package = current_package_response.data.data.extend_package.find(i => i.channel === 'atosa_spy')
        }
      }
    }
  }
}
</script>

<style>
.profile-wrapper {
  display: flex;
  padding-top: 30px;
}

.profile-wrapper .user-info {
  width: 55%;
  min-height: 200px;
  background-color: #fff;
  border-radius: 4px;
  margin-right: 30px;
  padding: 20px 40px;
}

.profile-wrapper .user-info .title {
  font-size: 20px;
  margin-bottom: 20px;
}

.profile-wrapper .user-info .item {
  width: 100%;
  display: flex;
  padding: 20px 0;
  /*border-bottom: 1px solid #e4e4e4;*/
  /*align-items: center;*/
}

.profile-wrapper .user-info .item .label {
  width: 30%;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.profile-wrapper .user-info .item .value {
  width: 70%;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.profile-wrapper .user-info .submit {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
}

.profile-wrapper .user-info .submit div:first-child {
  flex: auto;
  font-size: 14px;
}

.profile-wrapper .user-info .submit div:last-child {
  flex: auto;
  text-align: right;
}

.profile-wrapper .package-info {
  width: auto;
  min-height: 200px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 40px;
  position: relative;
}

.profile-wrapper .package-info .title {
  font-size: 20px;
  margin-bottom: 20px;
}

.profile-wrapper .package-info .info {
  display: flex;
  margin-bottom: 20px;
}

.profile-wrapper .package-info .info div {
  font-size: 30px;
  font-weight: bold;
  /*margin-bottom: 20px;*/
}

.profile-wrapper .package-info .info div:first-child {
  margin-right: 20px;
}

.fixed-input {
  padding-right: 20px;
}

.fixed-input input {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-radius: unset;
  padding: 0;
}

.password-wrapper {
  display: flex;
}

.password-wrapper .content {
  width: 55%;
  margin-top: 30px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 40px;
}

.password-wrapper .content .title {
  font-size: 20px;
  margin-bottom: 20px;
}

.password-wrapper .content .item {
  width: 100%;
  display: flex;
  padding: 20px 0;
}

.password-wrapper .content .item .label {
  width: 30%;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.password-wrapper .content .item .value {
  width: 70%;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.password-wrapper .content .submit {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
}

.password-wrapper .content .submit div:first-child {
  flex: auto;
  font-size: 14px;
}

.password-wrapper .content .submit div:last-child {
  flex: auto;
  text-align: right;
}
</style>
