import Vue from 'vue'

export const Bus = new Vue()

export const handleEventBus = {
  on (event, handler) {
    Bus.$on(event, handler)
  },

  /**
   * Listen for the given event once.
   *
   * @param {string} event
   * @param {function} handler
   */
  once (event, handler) {
    Bus.$once(event, handler)
  },

  /**
   * Remove one or more event listeners.
   *
   * @param {string} event
   * @param {function} handler
   */
  off (event, handler) {
    Bus.$off(event, handler)
  },

  /**
   * Emit the given event.
   *
   * @param {string|object} event
   * @param {...*} args
   */
  emit (event, ...args) {
    Bus.$emit(event, ...args)
  }
}

export default {
  install (Vue) {
    Vue.prototype.$bus = handleEventBus
  }
}
