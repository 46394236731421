import moment from 'moment'
import i18n from './lang'
import 'moment/locale/vi'

import { API, SuccessMessageDefault, ErrorMessageDefault } from '@/config'

moment.locale('vi')

export default {
  install (Vue) {
    Vue.prototype.$api = API

    Vue.prototype.$success_message = SuccessMessageDefault
    Vue.prototype.$error_message = ErrorMessageDefault

    Vue.prototype.remove_unicode = removeUnicode

    Vue.prototype.random_rgb_color = randomRGBColor

    Vue.prototype.convert_currency = convertCash

    Vue.prototype.percentage_calculator = percentageCalculator

    Vue.prototype.format_date_from_now = formatDateFromNow

    Vue.prototype.random_int = randomInt

    Vue.prototype.custom_index = customIndex

    Vue.prototype.color_list = AVATAR_COLOR_LIST

    Vue.prototype.translate_message = translateMessage

    Vue.prototype.short_value = shortValue

    Vue.prototype.open_link = openLink
  }
}

export function removeUnicode (value) {
  if (typeof value === 'string') {
    value = value.trim()
    value = value.toLowerCase()
    value = value.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/ig, 'a')
    value = value.replace(/[èéẹẻẽêềếệểễ]/ig, 'e')
    value = value.replace(/[ìíịỉĩ]/ig, 'i')
    value = value.replace(/[òóọỏõôồốộổỗơờớợởỡ]/ig, 'o')
    value = value.replace(/[ùúụủũưừứựửữ]/ig, 'u')
    value = value.replace(/[ỳýỵỷỹ]/ig, 'y')
    value = value.replace(/đ/ig, 'd')
    return value
  } else {
    return ''
  }
}

export function randomRGBColor () {
  const red = randomInt(0, 255)
  const green = randomInt(0, 255)
  const blue = randomInt(0, 255)
  return [red, green, blue]
}

export function convertCash (cash) {
  if (typeof cash === 'string' || typeof cash === 'number') {
    return parseInt(cash.toString()).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
  } else {
    console.log('Cash error: ', cash)
    return ''
  }
}

export function percentageCalculator (value = 100, percent = 100) {
  return value - (value * percent / 100)
}

export function formatDateFromNow (date) {
  return date ? moment(new Date(date)).fromNow().replace(/một/ig, '1') : ''
}

export function randomInt (min = 0, max = 77) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const AVATAR_COLOR_LIST = [
  '#CD6155', '#C0392B', '#A93226', '#922B21', '#7B241C', '#641E16',
  '#AF7AC5', '#9B59B6', '#AF7AC5', '#6C3483', '#5B2C6F', '#4A235A',
  '#5499C7', '#2980B9', '#2471A3', '#1F618D', '#1A5276', '#154360',
  '#5DADE2', '#3498DB', '#2E86C1', '#2874A6', '#21618C', '#1B4F72',
  '#48C9B0', '#1ABC9C', '#17A589', '#148F77', '#117864', '#138D75',
  '#16A085', '#117A65', '#0E6655', '#0B5345', '#52BE80', '#27AE60',
  '#229954', '#1E8449', '#196F3D', '#186A3B', '#58D68D', '#2ECC71',
  '#28B463', '#239B56', '#1D8348', '#186A3B', '#F4D03F', '#F1C40F',
  '#D4AC0D', '#9A7D0A', '#7D6608', '#F5B041', '#F39C12', '#CA6F1E',
  '#935116', '#784212', '#DC7633', '#D35400', '#A04000', '#873600',
  '#6E2C00', '#99A3A4', '#839192', '#717D7E', '#5F6A6A', '#4D5656',
  '#99A3A4', '#7F8C8D', '#707B7C', '#616A6B', '#515A5A', '#424949',
  '#5D6D7E', '#34495E', '#2E4053', '#283747', '#212F3C', '#212F3C'
]

// TODO: Custom index of table when paginate
// TODO: Use high order function: return function(value) { ...calculate }
export function customIndex (showEntry, currentPage) {
  return (index) => (index + 1) + (showEntry * (currentPage - 1))
}

export function translateMessage (message) {
  const list = [
    {
      message: 'Tài khoản hoặc mật khẩu không đúng, vui lòng kiểm tra và thử lại sau!',
      value: 'server_wrong_account'
    },
    {
      message: 'Captcha không hợp lệ, vui lòng kiểm tra và thử lại sau!',
      value: 'register_invalid_captcha'
    },
    {
      message: 'Địa chỉ email đã được sử dụng để đăng ký trước đó, vui lòng kiểm tra và thử lại sau!',
      value: 'register_account_exists'
    },
    {
      message: 'Tài khoản không tồn tại',
      value: 'server_wrong_account'
    },
    {
      message: 'Định dạng email không hợp lệ, vui lòng kiểm tra và thử lại sau.',
      value: 'invalid_email_format'
    },
    {
      message: 'Gói Shopee Marketing của quý khách đã hết hạn. Vui lòng gia hạn gói để tiếp tục sử dụng.',
      value: 'package_is_expr'
    },
    {
      message: 'Đã cập nhật tệp từ khóa',
      value: 'keyword_file_updated'
    },
    {
      message: 'Đã xóa tệp từ khóa',
      value: 'keyword_file_deleted'
    },
    {
      message: 'Mật khẩu hiện tại không chính xác, vui lòng kiểm tra và thử lại sau!',
      value: 'current_password_not_correct'
    },
    {
      message: 'Mật khẩu hiện tại không đúng, vui lòng kiểm tra và thử lại sau!',
      value: 'current_password_not_correct'
    },
    {
      message: 'Địa chỉ email không hợp lệ vui lòng kiểm tra và thử lại sau!',
      value: 'invalid_email'
    },
    {
      message: 'Số điện thoại không hợp lệ, vui lòng kiểm tra và thử lại sau!',
      value: 'invalid_phone_number'
    },
    {
      message: 'Tên hiển thị không hợp lệ, vui lòng kiểm tra và thử lại sau!',
      value: 'invalid_display_name'
    },
    {
      message: 'Thời hạn bản quyền hết hạn hoặc số cửa hàng đạt giới hạn!',
      value: 'limited_shop'
    },
    {
      message: 'Không thể xác minh OTP. Vui lòng báo với quản trị viên.',
      value: 'cant_verify_otp'
    },
    {
      message: 'Bản quyền không hợp lệ',
      value: 'invalid_license'
    },
    {
      message: '403 Forbidden Error',
      value: 'forbidden'
    },
    {
      message: 'bad body params',
      value: 'an_error'
    }
  ]

  const found = list.find(i => i.message === message || i.message.startsWith(message))
  if (found) {
    return i18n.t(found.value)
  } else {
    console.warn(`${message} not found`)
    return 'an_error'
  }
}

export function shortValue (value, is_percent, min = 10000) {
  if (!value) return '0'
  if (!Number(value)) return value
  if (!is_percent) value = Math.floor(value)

  const one_thousand = 1000
  const one_million = one_thousand * one_thousand
  const one_billion = one_million * one_thousand

  let result = ''
  if (value >= one_billion) {
    if (value % one_billion > 0) {
      result = `${(value / one_billion).toFixed(1)}B`
    } else {
      result = `${Math.floor(value / one_billion)}B`
    }
  } else if (value >= one_million) {
    if (value % one_million > 0) {
      result = `${(value / one_million).toFixed(1)}M`
    } else {
      result = `${Math.floor(value / one_million)}M`
    }
  } else if (value >= one_thousand && value > min) {
    if (value % one_thousand > 0) {
      result = `${(value / one_thousand).toFixed(1)}K`
    } else {
      result = `${Math.floor(value / one_thousand)}K`
    }
  } else {
    result = value
  }

  return `${result} ${is_percent ? '%' : ''}`
}

export function openLink (link, target = '_blank') {
  window.open(link, target).focus()
}
