import moment from 'moment'

export default {
  install (Vue) {
    Vue.filter('moment', (value, format = 'HH:mm DD/MM/YYYY') => value ? moment(new Date(value)).format(format) : '')

    Vue.filter('moment_local', (value, format = 'DD/MM/YYYY') => value ? moment(new Date(value)).format(format) : '')

    Vue.filter('currency', (value, defaultValue = true) => value ? parseInt(value.toString()).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.') : (defaultValue ? 0 : ''))

    Vue.filter('upper_first_letter', (value) => value ? value.charAt(0).toUpperCase() + value.slice(1) : '')

    Vue.filter('format_from_now', (value) => value ? moment(new Date(value)).fromNow().replace(/một/ig, '1') : '')
  }
}
